import { useMemo } from 'react'
import { createFileRoute, useRouter } from '@tanstack/react-router'
import { pick, sortBy } from 'lodash-es'
import { XIcon } from 'lucide-react'
import { ArrayValues } from 'type-fest'
import { z } from 'zod'

import { dayjs } from '@/lib/dayjs.ts'
// import { ScrollArea } from '@/components/ui/scroll-area.tsx'
import { BigText } from '@/components/BigText.tsx'
import { DateFormat } from '@/components/date-format.tsx'
import { DefaultLayout } from '@/components/layouts/DefaultLayout.tsx'
import { Money } from '@/components/money.tsx'
import { ScrollAreaWithShadows } from '@/components/scroll-area-shadow.tsx'
import { SelectionButton } from '@/components/SelectionButton.tsx'
import { Spinner } from '@/components/Spinner.tsx'
import { Ambulatorio } from '@/features/totem-pos/ambulatorio.tsx'
import { useFindPrestazioni } from '@/features/totem-pos/use-find-prestazioni.ts'
import { useSessionTimeout } from '@/routes/totem-eliminacode/$ambulatorioId/-hooks.ts'
import { checkinParametersSchema } from '@/routes/totem-pos/$ospedaleId/-checkin-parameters-schema.ts'

export const Route = createFileRoute('/totem-pos/$ospedaleId/3-select-service')(
  {
    component: SelectServiceStep,
    validateSearch: z.intersection(
      z.object({
        status: z
          .enum(['idle', 'futureEvent', 'exempt', 'exemptAndFutureEventError'])
          .optional(),
      }),
      checkinParametersSchema
    ),
  }
)

export function SelectServiceStep() {
  const { ospedaleId } = Route.useParams()
  const searchParams = Route.useSearch()
  const {
    checkinMethod,
    checkinValue,
    status: st,
    prestazioneId,
  } = searchParams
  const navigate = Route.useNavigate()
  const fetchPrestazioniQuery = useFindPrestazioni({
    ospedaleId,
    searchMethod: checkinMethod,
    searchValue: checkinValue,
  })

  const status = st ?? 'idle'

  const prestazioni = useMemo(
    () =>
      fetchPrestazioniQuery.data?.success
        ? sortBy(
            fetchPrestazioniQuery?.data?.data?.records,
            ['data_convocazione'],
            ['asc']
          )
        : [],
    [fetchPrestazioniQuery.data]
  )
  const router = useRouter()

  const { timeoutCounter, resetSession } = useSessionTimeout({
    seconds: 30,
    postponeOnActivity: true,
  })

  const handleSelection = (
    prestazione: ArrayValues<NonNullable<typeof prestazioni>>
  ) => {
    // TODO
    const prestazioneId = prestazione.id_interno
    const isExempt = !prestazione.iuv
    const isFutureEvent = dayjs(prestazione.data_convocazione).isAfter()
    if (isExempt && isFutureEvent) {
      navigate({
        search: {
          ...searchParams,
          prestazioneId,
          status: 'exemptAndFutureEventError',
        },
      })
      return
    }

    if (isFutureEvent) {
      navigate({
        search: {
          ...searchParams,
          prestazioneId,
          status: 'futureEvent',
        },
      })
      return
    }

    if (isExempt) {
      navigate({
        to: '/totem-pos/$ospedaleId/4-check-in-exempt',
        search: {
          ...searchParams,
          numeroPrenotazione: prestazione.numero_prenotazione,
        },
      })
      return
    }

    navigate({
      to: '/totem-pos/$ospedaleId/4-payment',
      params: { ospedaleId },
      search: {
        ...pick(searchParams, ['checkinMethod', 'checkinValue']),
        prestazioneId,
        iuv: prestazione.iuv!,
      },
    })
  }

  const handleFutureEventReasonSelection = (
    reason: 'earlyPayment' | 'earlyEvent'
  ) => {
    const prestazione = prestazioni?.find((p) => p.id_interno === prestazioneId)
    if (!prestazione) {
      throw new Error('invalid')
    }

    navigate({
      to: '/totem-pos/$ospedaleId/4-payment',
      params: { ospedaleId },
      search: {
        ...pick(searchParams, ['checkinMethod', 'checkinValue']),
        iuv: prestazione.iuv!,
        note: reason,
      },
    })
  }

  const isEmpty = !fetchPrestazioniQuery.isPending && !prestazioni?.length

  return (
    <>
      {status === 'idle' && !isEmpty && (
        <DefaultLayout.Header className="mb-6">
          <BigText>Selezionare la prestazione sanitaria</BigText>
        </DefaultLayout.Header>
      )}
      {status === 'futureEvent' && (
        <DefaultLayout.Header className="mb-6">
          <BigText>Hai selezionato un evento futuro</BigText>
        </DefaultLayout.Header>
      )}

      <DefaultLayout.Body className="justify-center overflow-y-hidden">
        {(status === 'idle' || !status) && (
          <>
            {fetchPrestazioniQuery.isPending && <Spinner />}
            {fetchPrestazioniQuery.isSuccess && (
              <ScrollAreaWithShadows className="flex h-full flex-col justify-around gap-y-10">
                {prestazioni?.map((prestazione, index) => (
                  <SelectionButton
                    key={index}
                    className="flex max-h-60 flex-row items-center justify-between"
                    onClick={() => handleSelection(prestazione)}
                  >
                    <div className="flex flex-col items-start gap-y-2">
                      <BigText className="text-white">
                        <Ambulatorio value={prestazione.ambulatorio} />
                      </BigText>
                      <BigText className="text-white" size="md">
                        <DateFormat
                          date={prestazione.data_convocazione}
                          format="dddd DD MMMM YYYY [alle] HH:mm"
                          todayFormat="[oggi alle] HH:mm"
                          tomorrowFormat="[domani alle] HH:mm"
                        />
                      </BigText>
                      {/*{prestazione.ambulatorio && (*/}
                      {/*  <BigText*/}
                      {/*    className="inline-block truncate text-white"*/}
                      {/*    size="md"*/}
                      {/*  >*/}
                      {/*    Ambulatorio: {prestazione.ambulatorio}*/}
                      {/*    {prestazione.ambulatorio}*/}
                      {/*  </BigText>*/}
                      {/*)}*/}
                    </div>

                    <BigText className="flex-none text-white">
                      {prestazione.iuv ? (
                        <Money value={prestazione.importo} />
                      ) : (
                        'Esente'
                      )}
                    </BigText>
                  </SelectionButton>
                ))}

                {isEmpty && (
                  <div className="flex w-full flex-col items-center">
                    <XIcon className="size-36 text-red-600" />
                    <BigText className="flex text-center">
                      Nessuna prestazione sanitaria trovata
                    </BigText>
                  </div>
                )}
              </ScrollAreaWithShadows>
            )}
          </>
        )}

        {status == 'futureEvent' && (
          <div className="flex h-full flex-col justify-around gap-y-20 border-gray-300">
            <SelectionButton
              className="max-h-60 uppercase"
              onClick={() => handleFutureEventReasonSelection('earlyPayment')}
            >
              Voglio effettuare il pagamento adesso
            </SelectionButton>

            <SelectionButton
              className="max-h-60 uppercase"
              onClick={() => handleFutureEventReasonSelection('earlyEvent')}
            >
              La prestazione è anticipata
            </SelectionButton>
          </div>
        )}

        {status == 'exempt' && (
          <div className="mx-auto max-w-screen-2xl text-center">
            <BigText className="mb-10">
              Sei sicuro di voler proseguire con l&apos;accettazione?
            </BigText>

            <div className="flex justify-around gap-x-20 border-gray-300">
              <SelectionButton
                className="items-center uppercase"
                size="lg"
                variant="danger"
                onClick={() => router.history.back()}
              >
                No
              </SelectionButton>

              <SelectionButton
                className="items-center uppercase"
                size="lg"
                onClick={() => handleFutureEventReasonSelection('earlyEvent')}
              >
                Si
              </SelectionButton>
            </div>
          </div>
        )}

        {status === 'exemptAndFutureEventError' && (
          <div className="flex flex-col items-center justify-center">
            <XIcon className="size-36 text-red-600" />
            <BigText className="flex text-center">
              Hai selezionato una prestazione sanitaria FUTURA, per la quale sei
              ESENTE dal pagamento. <br />
              Rivolgersi allo sportello.
            </BigText>
          </div>
        )}
      </DefaultLayout.Body>
      <DefaultLayout.Footer
        className="grid grid-cols-3 justify-around"
        backBtnVisible={status !== 'exempt'}
        timeoutCounter={timeoutCounter}
        {...(isEmpty && { onBackBtnClick: resetSession })}
      />
    </>
  )
}
