import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { InferRequestType, InferResponseType } from 'hono/client'

import { apiClient } from '@/apiClient.ts'

type UseSendInvoiceArguments = InferRequestType<
  (typeof apiClient.api)['totem-pos']['invoice']['$post']
>['json']
type UseSendInvoiceResponse = InferResponseType<
  (typeof apiClient.api)['totem-pos']['invoice']['$post']
>

export const useSendInvoice = (
  options?: UseMutationOptions<
    UseSendInvoiceResponse,
    Error,
    UseSendInvoiceArguments
  >
) => {
  return useMutation<UseSendInvoiceResponse, Error, UseSendInvoiceArguments>({
    mutationKey: ['send-invoice'],
    mutationFn: async (variables) => {
      return apiClient.api['totem-pos'].invoice
        .$post({ json: variables })
        .then((r) => r.json())
    },
    ...options,
  })
}
