import { useEffect } from 'react'
import { createFileRoute, Outlet } from '@tanstack/react-router'

import { DefaultLayout } from '@/components/layouts/DefaultLayout.tsx'
import { useKioskStatus } from '@/features/kiosk/useKioskStatus.ts'
import { useTotemPos } from '@/features/totem-pos/use-totem-pos.ts'

export const Route = createFileRoute('/totem-pos/$ospedaleId')({
  component: TicketIndex,
})

function TicketIndex() {
  useKioskStatus({ performRedirect: true })
  const { ospedaleId } = Route.useParams()
  useTotemPos(ospedaleId, {
    gcTime: 0,
    staleTime: 0,
  })

  useEffect(() => {
    if (import.meta.env.DEV) return

    document.oncontextmenu = () => false

    return () => {
      document.oncontextmenu = () => true
    }
  }, [])

  return (
    <>
      <DefaultLayout>
        <Outlet />
        {/*<OfflineWarning />*/}
      </DefaultLayout>
    </>
  )
}
