import { createFileRoute } from '@tanstack/react-router'
import { CheckIcon, ChevronLeft } from 'lucide-react'
import { z } from 'zod'

import { BigText } from '@/components/BigText.tsx'
import { DefaultLayout } from '@/components/layouts/DefaultLayout.tsx'
import { SelectionButton } from '@/components/SelectionButton.tsx'
import { useSessionTimeout } from '@/routes/totem-eliminacode/$ambulatorioId/-hooks.ts'

export const Route = createFileRoute('/totem-pos/$ospedaleId/6-success')({
  component: SuccessStep,
  validateSearch: z.object({ message: z.string().optional() }),
})

function SuccessStep() {
  const { message } = Route.useSearch()
  const navigate = Route.useNavigate()
  const { timeoutCounter } = useSessionTimeout({ seconds: 15 })

  return (
    <>
      <DefaultLayout.Body
        className="flex grow flex-col items-center justify-center"
        onClick={() => navigate({ to: '/' })}
      >
        <div>
          <CheckIcon className="mx-auto size-36 text-green-600" />
          <BigText className="whitespace-pre-wrap text-center">
            {message ?? 'Grazie, arrivederci'}
          </BigText>
        </div>

        <div>
          <SelectionButton
            className="mt-36 flex-row items-center px-36"
            onClick={() => navigate({ to: '/' })}
            variant="danger"
          >
            <ChevronLeft className="mr-1" size={60} /> Ricomincia
          </SelectionButton>
        </div>
      </DefaultLayout.Body>

      <DefaultLayout.Footer timeoutCounter={timeoutCounter} />
    </>
  )
}
