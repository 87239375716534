import { createFileRoute, Link } from '@tanstack/react-router'
import { ChevronRightIcon } from 'lucide-react'

import { useUpdateApp } from '@/hooks/useUpdateApp.ts'
import { BigText } from '@/components/BigText.tsx'
import { DefaultLayout } from '@/components/layouts/DefaultLayout.tsx'
import { Touchable } from '@/components/Touchable.tsx'

import facsimileFoglioNumeroMonitor from '../../../assets/images/totem/facsimile-prenotazione.jpg'

export const Route = createFileRoute(
  '/totem-eliminacode/$ambulatorioId/1-instructions'
)({
  component: InstructionsStep,
})

export function InstructionsStep() {
  const params = Route.useParams()
  useUpdateApp()

  return (
    <>
      <DefaultLayout.Body className="justify-center">
        {/*Main grid*/}
        <div className="flex flex-col gap-28 xl:flex-row">
          {/*Left instructions with image*/}
          <div className="flex basis-3/5 gap-x-10 border-2 border-gray-300 bg-white/50 p-4">
            <div className="flex basis-2/5 items-center text-center">
              <BigText size="lg">
                Sei già in possesso di un numero monitor?
              </BigText>
            </div>
            <div className="flex basis-3/5 flex-col space-y-6">
              {/*<video*/}
              {/*  className="size-full"*/}
              {/*  src={foglioNumeroMonitorVideo}*/}
              {/*  autoPlay*/}
              {/*  loop*/}
              {/*  muted*/}
              {/*/>*/}

              <img src={facsimileFoglioNumeroMonitor} className="w-full" />
            </div>
          </div>

          {/*Selections yes/no*/}
          <div className="flex h-full basis-2/5 flex-col justify-center uppercase">
            <div className="flex h-full flex-row justify-around gap-10 gap-y-20 bg-white xl:flex-col xl:border-2 xl:border-gray-300 xl:p-10">
              {/*Yes*/}
              <div className="flex w-full items-center gap-x-10">
                <Touchable>
                  <Link
                    to={'/totem-eliminacode/$ambulatorioId/2-go-waiting-room'}
                    params={params}
                    className="w-full"
                  >
                    <BigText asChild data-testId="yes-btn">
                      <button className="flex w-full items-center justify-between border-2 bg-gradient-to-r from-blue-600 to-violet-600 p-8 tracking-wider text-white">
                        SI
                        <ChevronRightIcon className="size-20" />
                      </button>
                    </BigText>
                  </Link>
                </Touchable>
              </div>

              {/*No*/}
              <div className="flex w-full items-center gap-x-10">
                <Touchable>
                  <Link
                    to={'/totem-eliminacode/$ambulatorioId/2-selection'}
                    params={params}
                    className="w-full"
                  >
                    <BigText asChild data-testId="no-btn">
                      <button className="flex w-full items-center justify-between border-2 bg-gradient-to-r from-blue-600 to-violet-600 p-8 tracking-wider text-white">
                        NO
                        <ChevronRightIcon className="size-20" />
                      </button>
                    </BigText>
                  </Link>
                </Touchable>
              </div>
            </div>
          </div>
        </div>
      </DefaultLayout.Body>

      {/*<DefaultLayout.Footer backBtnVisible={true} />*/}
    </>
  )
}
