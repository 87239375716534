import { useEffect, useState } from 'react'
import { createFileRoute, Link } from '@tanstack/react-router'
import { XIcon } from 'lucide-react'
import { z } from 'zod'

import { pagoPaRegex, ricettaRegex } from '@/lib/pagopa-regex.ts'
import { useCardScan } from '@/hooks/useCardScan.ts'
import { BigText } from '@/components/BigText.tsx'
import { DefaultLayout } from '@/components/layouts/DefaultLayout.tsx'
import { SelectionButton } from '@/components/SelectionButton.tsx'
import facsimileBollettino from '@/assets/images/totem/facsimile-bollettino.gif'
import facsimileRicetta from '@/assets/images/totem/facsimile-ricetta.jpg'
import useCardVideo from '@/assets/videos/use-card-2.mp4'
import { useSessionTimeout } from '@/routes/totem-eliminacode/$ambulatorioId/-hooks.ts'

const fiscalCodeRegex = new RegExp(
  "(?<fiscalCode>[A-Za-z]{6}[0-9LMNPQRSTUV]{2}[A-Za-z]{1}[0-9LMNPQRSTUV]{2}[A-Za-z]{1}[0-9LMNPQRSTUV]{3}[A-Za-z]{1})(\\s?(?<lastname>[A-Za-z']+\\s{1,2}[A-Za-z']+|[A-Za-z']+)\\s{1,2}(?<firstname>([A-Za-z']+\\s[A-Za-z']+)|[A-Za-z']+)?)?"
)

export const Route = createFileRoute('/totem-pos/$ospedaleId/2-scan')({
  component: CardScanStep,
  validateSearch: z.object({
    scanType: z.enum([
      'bollettino-pagopa',
      'numero-ricetta',
      'id-card-o-tess-sanitaria',
    ]),
  }),
})

export function CardScanStep() {
  // const navigate = useNavigate()
  const params = Route.useParams()
  const { scanType } = Route.useSearch()
  const navigate = Route.useNavigate()
  // const startOver = () => {
  //   navigate({ to: '/totem-eliminacode/$ambulatorioId', params })
  // }

  const [status, setStatus] = useState<'waiting' | 'scanError'>('waiting')
  useEffect(() => {
    if (status === 'scanError') {
      const timeout = setTimeout(() => {
        setStatus('waiting')
      }, 4000)

      return () => {
        clearTimeout(timeout)
      }
    }
  }, [status])

  const { timeoutCounter, startTimeout } = useSessionTimeout({
    seconds: 60,
    postponeOnActivity: true,
  })

  useCardScan({
    onScan(input, patternMatch) {
      if (!patternMatch) {
        return setStatus('scanError')
      }
      if (patternMatch === pagoPaRegex) {
        const { iuv } = patternMatch.exec(input)!.groups as { iuv: string }

        navigate({
          to: '/totem-pos/$ospedaleId/4-payment',
          params,
          search: { checkinMethod: 'IUV', checkinValue: iuv, iuv },
        })
      }

      if (patternMatch === fiscalCodeRegex) {
        const codiceFiscale = patternMatch.exec(input)?.groups?.fiscalCode

        navigate({
          to: '/totem-pos/$ospedaleId/3-select-service',
          params,
          search: { checkinMethod: 'CF', checkinValue: codiceFiscale! },
        })
      }

      if (patternMatch === ricettaRegex) {
        const ricetta = ricettaRegex.exec(input)?.groups?.ricetta

        navigate({
          to: '/totem-pos/$ospedaleId/3-select-service',
          params,
          search: { checkinMethod: 'RICETTA', checkinValue: ricetta! },
        })
      }

      startTimeout()
    },
    patterns: [pagoPaRegex, fiscalCodeRegex, ricettaRegex],
  })

  return (
    <>
      <DefaultLayout.Body className="items-center justify-center">
        {scanType === 'bollettino-pagopa' && (
          <>
            {status === 'waiting' && (
              <div className="grid h-full max-w-screen-2xl grow grid-cols-2 items-center justify-center gap-x-10 p-4">
                <div className="flex h-full items-center text-center">
                  <BigText size="lg" className="font-normal">
                    Scansiona <br />
                    il <span className="text-nowrap font-bold">
                      QR Code
                    </span>{' '}
                    del{' '}
                    <span className="text-nowrap font-bold">
                      bollettino PagoPa
                    </span>
                  </BigText>
                </div>
                <div className="relative size-full grow">
                  <img
                    src={facsimileBollettino}
                    className="absolute inset-0 size-full object-contain"
                  />
                </div>
              </div>
            )}

            {status === 'scanError' && (
              <>
                <XIcon className="size-36 text-red-600" />
                <BigText className="flex text-center">
                  Lettura bollettino non valida <br />
                  Riprovare
                </BigText>
              </>
            )}
          </>
        )}

        {scanType === 'id-card-o-tess-sanitaria' && (
          <>
            {status === 'waiting' && (
              <div className="grid h-full max-w-screen-2xl grow grid-cols-2 items-center justify-center gap-x-10 p-4">
                <div className="flex h-full items-center text-center">
                  <BigText size="lg" className="font-normal">
                    Utilizzare{' '}
                    <span className="text-nowrap font-bold">
                      la carta d&apos;identità
                    </span>{' '}
                    o{' '}
                    <span className="text-nowrap font-bold">
                      la tessera sanitaria
                    </span>{' '}
                    del paziente
                  </BigText>
                </div>
                <div className="h-[50rem] w-full">
                  <video
                    className="mx-auto h-full object-contain"
                    src={useCardVideo}
                    autoPlay
                    loop
                    muted
                  />
                </div>
              </div>
            )}

            {status === 'scanError' && (
              <>
                <XIcon className="size-36 text-red-600" />
                <BigText className="flex text-center">
                  Lettura carta non valida <br />
                  Riprovare
                </BigText>
              </>
            )}
          </>
        )}

        {scanType === 'numero-ricetta' && (
          <>
            <div className="grid h-full max-w-screen-2xl grow grid-cols-2 items-center justify-center gap-x-10 p-4">
              <div className="flex h-full items-center text-center">
                <BigText size="lg" className="font-normal">
                  Scansiona il{' '}
                  <span className="text-nowrap font-bold">codice a barre</span>{' '}
                  della <span className="text-nowrap font-bold">ricetta</span>{' '}
                  del medico
                </BigText>
              </div>
              <div className="w-full grow">
                <img
                  src={facsimileRicetta}
                  className="mx-auto object-contain"
                />
              </div>
            </div>
          </>
        )}
      </DefaultLayout.Body>

      <DefaultLayout.Footer
        backBtnVisible={true}
        backBtnLabel="Ricomincia"
        // onBackBtnClick={startOver}
        timeoutCounter={timeoutCounter}
        className="grid grid-cols-3 justify-around"
      >
        {(scanType === 'id-card-o-tess-sanitaria' ||
          scanType === 'numero-ricetta') && (
          <Link
            className="mx-auto inline-block text-center"
            to="/totem-pos/$ospedaleId/2-manual-input"
            params={{ ...params }}
            search={{
              inputType: 'CF',
              ...(scanType === 'id-card-o-tess-sanitaria' && {
                inputType: 'CF',
              }),
              ...(scanType == 'numero-ricetta' && {
                inputType: 'RICETTA',
              }),
            }}
          >
            <SelectionButton size="md" variant="muted" data-testId="go-manual">
              {scanType === 'id-card-o-tess-sanitaria' &&
                'Digita Codice Fiscale'}
              {scanType === 'numero-ricetta' && 'Inserisci a mano'}
            </SelectionButton>
          </Link>
        )}
      </DefaultLayout.Footer>
    </>
  )
}
