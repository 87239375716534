import React, { useMemo } from 'react'
import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation, useQuery } from '@tanstack/react-query'
import { getRouteApi } from '@tanstack/react-router'
import { to } from 'await-to-js'
import { LogOutIcon, PrinterIcon } from 'lucide-react'
import { useForm } from 'react-hook-form'
import { Config, configSchema } from 'shared'

import { bus } from '@/lib/bus.ts'
import { cn } from '@/lib/utils'
import { useTimeoutCounter } from '@/hooks/useTimeoutCounter'
import { Button } from '@/components/ui/button'
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
import { Separator } from '@/components/ui/separator'
import { SubmitButton } from '@/components/SubmitButton'
import { useConfig, useUpdateConfig } from '@/features/kiosk/store.ts'
import { useLicense } from '@/features/license/license.ts'
import { useTotemEliminacode } from '@/features/totem-eliminacode/use-totem-eliminacode.ts'

export interface ConfigFormProps {
  onSave?: () => void
}

const route = getRouteApi('/getting-started/3-configuration')

export const ConfigForm: React.FC<ConfigFormProps> = ({ onSave }) => {
  const navigate = route.useNavigate()
  const configQuery = useConfig()
  const licenseQuery = useLicense()
  const types = licenseQuery.data?.success
    ? licenseQuery.data.data?.types
    : null

  const form = useForm<Config>({
    // @ts-expect-error that's fine
    values: { ...configQuery.data },
    resolver: zodResolver(configSchema),
  })

  // Printing
  const printersQuery = useQuery({
    queryKey: ['printers'],
    queryFn: () => bus.getPrinters(),
  })
  const printers = printersQuery.data

  const defaultPrinter = useMemo(
    () => printers?.find((printer) => printer.isDefault),
    [printers]
  )

  const sendToPrintTimeout = useTimeoutCounter()
  const sendTestPrintMutation = useMutation({
    mutationFn: async () => {
      const selectedPrinterName = form.getValues().printerName
      const printerName =
        selectedPrinterName === '__defaultPrinter'
          ? defaultPrinter?.name
          : selectedPrinterName

      await bus.sendTestPrint(printerName!)

      sendToPrintTimeout.enable({ seconds: 3 })
    },
  })

  // configuration
  const updateStoreMutation = useUpdateConfig()

  // Eliminacode
  const eliminacodeQuery = useTotemEliminacode(form.watch().ambulatorioId, {
    enabled: false,
  })

  // Prenotazione

  const onSubmit = async () => {
    if (types?.includes('eliminacode')) {
      const [error, result] = await to(eliminacodeQuery.refetch())

      if (error || result.data?.esito !== 'OK') {
        form.setError('ambulatorioId', {
          type: 'custom',
          message: 'Codice ambulatorio non valido',
        })
        return
      }
    }

    await updateStoreMutation.mutateAsync(form.getValues())

    onSave?.()
  }

  const handleLogoutBtnClick = async () => {
    await window.bus.reset()
    navigate({ to: '/getting-started/1-authenticate' })
  }

  return (
    <>
      <div className="flex flex-col space-y-2 text-center">
        <h1 className="text-2xl font-semibold tracking-tight">
          Configurazione
        </h1>
      </div>
      {/*form data: {JSON.stringify(form.watch())} <br />*/}
      {/*configQuery.data: {JSON.stringify(configQuery.data)} <br />*/}
      {/*isValid: {JSON.stringify(form.formState.isValid)} <br />*/}
      {/*errors: {JSON.stringify({...form.formState.errors})} <br />*/}

      <Form {...form}>
        <form
          className="w-100 flex flex-col space-y-6"
          onSubmit={form.handleSubmit(onSubmit)}
        >
          {types?.includes('eliminacode') && (
            <FormField
              control={form.control}
              name="ambulatorioId"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Codice Ambulatorio</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormDescription>
                    Codice di esempio radiologia: 190107190914RADOG
                  </FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />
          )}

          {types?.includes('ticket') && (
            <>
              <FormField
                control={form.control}
                name="ospedaleId"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Codice Ospedale</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormDescription>
                      Codice di esempio ospedale: 190107190914
                    </FormDescription>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="rupp"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Codice RUPP POS</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormDescription>
                      Codice RUPP di esempio: N069288200503771
                    </FormDescription>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </>
          )}

          <Separator />

          <FormField
            control={form.control}
            name="printerName"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Stampante</FormLabel>
                <Select
                  onValueChange={field.onChange}
                  defaultValue={field.value}
                >
                  <FormControl>
                    <SelectTrigger>
                      <SelectValue placeholder="Seleziona la stampante" />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    <SelectItem value="__defaultPrinter">
                      Stampante predefinita{' '}
                      {!!defaultPrinter?.name && (
                        <span className="text-muted-foreground">
                          ({defaultPrinter?.name})
                        </span>
                      )}
                    </SelectItem>
                    <Separator />

                    {printers?.map((printer) => (
                      <SelectItem value={printer.name} key={printer.name}>
                        {printer.displayName}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="password"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Password</FormLabel>
                <FormControl>
                  <Input {...field} />
                </FormControl>
                <FormMessage />
                <FormDescription>
                  Questa sarà la password necessaria per disattivare la modalità
                  kiosk dell&apos;applicazione.
                </FormDescription>
              </FormItem>
            )}
          />

          {form.formState.errors?.root?.message && (
            <p className="text-sm font-medium text-destructive">
              {form.formState.errors.root.message}
            </p>
          )}

          <div className="grid grid-cols-2 gap-x-2">
            <Button
              type="button"
              variant="outline"
              className={cn(!form.watch().printerName && 'invisible')}
              onClick={() => sendTestPrintMutation.mutate()}
              disabled={
                sendTestPrintMutation.isPending || sendToPrintTimeout.isActive
              }
            >
              <PrinterIcon className="mr-2 size-4" />
              {sendToPrintTimeout.isActive
                ? 'Stampa in corso...'
                : 'Avvia una stampa di prova'}
            </Button>

            <SubmitButton
              type="submit"
              disabled={!form.formState.isValid}
              isSubmitting={form.formState.isSubmitting}
            >
              Salva
            </SubmitButton>
          </div>

          <Button
            type="button"
            variant="outline"
            onClick={handleLogoutBtnClick}
          >
            <LogOutIcon className="mr-2 size-4" />
            Logout
          </Button>
        </form>
      </Form>
    </>
  )
}
