import { useEffect, useState } from 'react'
import { queryOptions, useQuery } from '@tanstack/react-query'
import { InferRequestType } from 'hono/client'

import { apiClient } from '@/apiClient.ts'

type UseFetchPaymentArgs = InferRequestType<
  (typeof apiClient.api)['totem-pos']['payment'][':paymentId']['$get']
>['param']

export const fetchPaymentQueryOptions = (args: UseFetchPaymentArgs) =>
  queryOptions({
    queryKey: ['prestazioni', args],
    queryFn: () =>
      apiClient.api['totem-pos'].payment[':paymentId']
        .$get({
          param: { paymentId: args.paymentId },
        })
        .then((r) => r.json()),
    staleTime: 0,
  })

export const useFetchPayment = (
  args: UseFetchPaymentArgs,
  options?: Partial<ReturnType<typeof fetchPaymentQueryOptions>>
) => {
  return useQuery({
    ...fetchPaymentQueryOptions(args),
    ...options,
  })
}

export const usePollPayment = (
  args: UseFetchPaymentArgs,
  options?: Partial<ReturnType<typeof fetchPaymentQueryOptions>>
) => {
  const [refetchInterval, setRefetchInterval] = useState<number | false>(false)
  const fetchPaymentQuery = useFetchPayment(args, {
    refetchInterval: refetchInterval,
    ...options,
  })

  useEffect(() => {
    const data = fetchPaymentQuery?.data
    if (!data?.success) {
      setRefetchInterval(false)
      return
    }

    setRefetchInterval(data.data?.status === 'pending' ? 1000 : false)
  }, [fetchPaymentQuery?.data])

  return fetchPaymentQuery
}
