import { useCallback, useEffect, useMemo } from 'react'
import { useMutation } from '@tanstack/react-query'
import { createFileRoute, useRouter } from '@tanstack/react-router'
import { CheckIcon } from 'lucide-react'
import { z } from 'zod'

import { usePrintPdf } from '@/hooks/use-print-pdf.tsx'
import { BigText } from '@/components/BigText.tsx'
import { DefaultLayout } from '@/components/layouts/DefaultLayout.tsx'
import { SelectionButton } from '@/components/SelectionButton.tsx'
import { useCheckinExempt } from '@/features/totem-pos/use-check-in-exempt.ts'
import { Ticket } from '@/routes/-Ticket.tsx'
import { useSessionTimeout } from '@/routes/totem-eliminacode/$ambulatorioId/-hooks.ts'
import { checkinParametersSchema } from '@/routes/totem-pos/$ospedaleId/-checkin-parameters-schema.ts'

export const Route = createFileRoute(
  '/totem-pos/$ospedaleId/4-check-in-exempt'
)({
  component: CheckinExemptStep,
  validateSearch: z.intersection(
    z.object({
      numeroPrenotazione: z.string(),
      checkinStatus: z.enum(['idle', 'pending', 'success', 'error']).optional(),
    }),
    checkinParametersSchema
  ),
})

export function CheckinExemptStep() {
  const searchParams = Route.useSearch()
  const { numeroPrenotazione, checkinStatus } = searchParams
  const navigate = Route.useNavigate()
  const router = useRouter()
  const { timeoutCounter, resetSession /*startTimeout, resetSession*/ } =
    useSessionTimeout({
      seconds: 30,
      postponeOnActivity: true,
    })

  const setStatus = useCallback(
    (newCheckinStatus: typeof checkinStatus) =>
      navigate({
        search: {
          ...searchParams,
          checkinStatus: newCheckinStatus,
        },
      }),
    [navigate, searchParams]
  )

  // Check-in Exempt
  const checkInExemptMutation = useCheckinExempt({
    async onSuccess(data) {
      // const data = response.
      if (!data.success) {
        setStatus('error')
        return
      }

      if (data.success && data.data.esito.includes('OK')) {
        await printingTicketMutation.mutateAsync(data)
      }

      setStatus('success')
    },
    onError() {
      setStatus('error')
    },
  })

  useEffect(() => {
    if (!checkinStatus) setStatus('idle')

    if (checkInExemptMutation.isPending) setStatus('pending')
  }, [checkInExemptMutation.isPending, checkinStatus, setStatus])

  const formattedMessage = useMemo(() => {
    const [, ...messages] = checkInExemptMutation?.data?.success
      ? checkInExemptMutation.data?.data?.esito.split('\n') ?? []
      : []

    return messages
  }, [checkInExemptMutation.data])

  const handleCheckin = useCallback(() => {
    checkInExemptMutation.mutateAsync({ numeroPrenotazione })
  }, [checkInExemptMutation, numeroPrenotazione])

  const printPdfMutation = usePrintPdf()

  const printingTicketMutation = useMutation<
    null,
    Error,
    typeof checkInExemptMutation.data
  >({
    mutationKey: ['print-ticket'],
    mutationFn: async (variables) => {
      if (!variables?.success) return null
      const data = variables?.data

      const [, ...messages] = data?.esito.split('\n') ?? []

      await printPdfMutation.mutateAsync({
        template: <Ticket messages={messages} />,
      })

      return null
    },
  })

  return (
    <>
      <DefaultLayout.Body className="items-center justify-center">
        {checkinStatus == 'idle' && (
          <div className="mx-auto max-w-screen-2xl text-center">
            <BigText className="mb-10">
              Sei sicuro di voler proseguire con l&apos;accettazione?
            </BigText>

            <div className="flex justify-around gap-x-20 border-gray-300">
              <SelectionButton
                className="items-center uppercase"
                size="lg"
                variant="danger"
                onClick={() => router.history.back()}
              >
                No
              </SelectionButton>

              <SelectionButton
                className="items-center uppercase"
                size="lg"
                onClick={handleCheckin}
              >
                Si
              </SelectionButton>
            </div>
          </div>
        )}

        {checkinStatus == 'pending' && (
          <div className="flex grow flex-col items-center justify-center">
            <BigText>Check-in in corso...</BigText>
          </div>
        )}

        {checkinStatus == 'success' && (
          <>
            <CheckIcon className="mx-auto size-36 text-green-600" />
            <BigText
              size="md"
              className="mb-10 uppercase !leading-relaxed"
              data-testId="status-message"
            >
              Ritirare il numero, grazie
            </BigText>
            <BigText size="sm">
              {formattedMessage?.map((line) => <div key={line}>{line}</div>)}
            </BigText>
          </>
        )}
      </DefaultLayout.Body>
      <DefaultLayout.Footer
        className="flex justify-between"
        backBtnVisible={true}
        timeoutCounter={timeoutCounter}
        backBtnLabel="Annulla"
        {...(checkinStatus === 'success' && {
          backBtnLabel: 'Ricomincia',
          onBackBtnClick: resetSession,
        })}
      ></DefaultLayout.Footer>
    </>
  )
}
