import { useEffect } from 'react'
import { createRootRoute, Outlet } from '@tanstack/react-router'
import { ErrorInfo } from 'ably'
import { usePostHog } from 'posthog-js/react'

import { createLogger } from '@/lib/logger.ts'
import { useModalState } from '@/hooks/useModalState.ts'
import { useTouchSequenceDetector } from '@/hooks/useTouchSequenceDetector.tsx'
import { useAppUpdateStore } from '@/hooks/useUpdateApp.ts'
import { AuthenticateModal } from '@/components/AuthenticateModal.tsx'
import ErrorBoundary from '@/components/ErrorBoundary.tsx'
import { apiClient } from '@/apiClient.ts'
import beamBg from '@/assets/images/backgrounds/beams-home@95.jpeg'
import { useAbly, usePresence } from '@/features/ably/ably.tsx'
import { useLicense } from '@/features/license/license.ts'

export const Route = createRootRoute({
  component: Root,
})

const ablyLogger = createLogger({ name: 'ably' })

function Root() {
  const authenticateModal = useModalState()
  useTouchSequenceDetector([1, 2, 3, 4], () => {
    authenticateModal.open()
  })

  const licenseQuery = useLicense()

  const { setAuthCallback, connectionState: ablyConnectionState } = useAbly()
  useEffect(() => {
    if (licenseQuery.data?.success) {
      setAuthCallback(async (_, callback) => {
        ablyLogger.info('Calling Ably authCallback')
        const result = await apiClient.api.ably.token.$get()
        const data = await result.json()
        if ('error' in data) {
          ablyLogger.info({ data }, 'Ably authCallback errored')
          callback(
            new ErrorInfo('Error while getting the token', 401, 401),
            null
          )
          return
        }

        ablyLogger.info('Ably authCallback completed successfully')
        callback(null, data)
      })
    }
  }, [licenseQuery.data?.success])
  usePresence('global-presence')

  const posthog = usePostHog()
  useEffect(() => {
    if (
      ablyConnectionState === 'failed' ||
      ablyConnectionState === 'suspended'
    ) {
      const data = { state: ablyConnectionState }
      ablyLogger.error(
        data,
        'Invalid ably connection state, trying to explicitly reconnect'
      )
      posthog?.capture('ably_connection_state_invalid', data)

      // refresh as soon as possible
      useAppUpdateStore.getState().setHasUnrecoverableAblyState(true)
    }
  }, [ablyConnectionState, posthog])

  return (
    <ErrorBoundary>
      {/*{JSON.stringify({ ablyConnectionState })}*/}
      {/*{JSON.stringify({ connectionError })}*/}
      {/*route:{' '}*/}
      {/*{JSON.stringify({*/}
      {/*  pathname: router.location.pathname,*/}
      {/*  search: router.location.search,*/}
      {/*})}{' '}*/}
      {/*<br />*/}
      {/*tokenQuery.fetchStatus: {JSON.stringify(tokenQuery.fetchStatus)} <br />*/}
      {/*licenseQuery.fetchStatus: {JSON.stringify(licenseQuery.fetchStatus)}{' '}*/}
      {/*<br />*/}
      {/*isConfigValidQuery.fetchStatus:{' '}*/}
      {/*{JSON.stringify(isConfigValidQuery.fetchStatus)} <br />*/}
      {/*status: {status} <br />*/}
      {/*initialCheckPerformed: {JSON.stringify(initialCheckPerformed)}*/}
      <div className="relative flex min-h-screen w-screen overflow-hidden">
        <div className="absolute inset-x-0 top-0 z-0 overflow-hidden pl-[50%]">
          <img
            src={beamBg}
            alt=""
            className="ml-[-39rem] w-[113.125rem] max-w-none"
          />
        </div>

        <main className="relative z-10 h-screen flex-1">
          <Outlet />
        </main>
      </div>

      <AuthenticateModal
        isOpen={authenticateModal.isOpen}
        onClose={authenticateModal.close}
      />
    </ErrorBoundary>
  )
}
