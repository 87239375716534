import { AppType } from 'api'
import { hc } from 'hono/client'

import { bus } from '@/lib/bus.ts'
import { getTokenQueryOptions } from '@/features/auth/token.ts'

import { queryClient } from './queryClient'

export const apiClient = hc<AppType>(import.meta.env.VITE_API_BASE, {
  // @ts-expect-error fix type
  fetch: async (input, init) => {
    const headers = new Headers(init?.headers)
    const token =
      queryClient.getQueryData<string>(getTokenQueryOptions().queryKey) ??
      (await bus.getToken())
    if (token) {
      headers.set('authorization', token)
    }

    const response = await fetch(input, {
      ...init,
      headers,
    })

    if (response.status >= 400) {
      let responseBody: unknown
      try {
        responseBody = await response.json() // Assume JSON response; adjust if your API uses a different format
      } catch (error) {
        responseBody = await response.text()
      }

      throw new HcFetchError(response.status, responseBody)
    }

    return response
  },
})

// Define a custom error type for fetch operations
export class HcFetchError extends Error {
  public statusCode: number
  public data: unknown

  constructor(statusCode: number, responseBody: any) {
    super(`Request failed with status ${statusCode}`)
    this.name = 'HcFetchError' // Set the error name
    this.statusCode = statusCode
    this.data = responseBody

    // Maintain proper stack trace for where our error was thrown (only available on V8 engines)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, HcFetchError)
    }
  }
}
