import { useQuery } from '@tanstack/react-query'

import {
  findPrestazioniQueryOptions,
  useFindPrestazioni,
  UseFindPrestazioniArgs,
} from '@/features/totem-pos/use-find-prestazioni.ts'

type UseFindPrestazioneArgs = UseFindPrestazioniArgs & { prestazioneId: string }

export const useGetPrestazione = (
  args: UseFindPrestazioneArgs,
  options?: Partial<ReturnType<typeof findPrestazioniQueryOptions>>
) => {
  const { prestazioneId, ...rest } = args

  const findPrestazioniQuery = useFindPrestazioni(rest, options)

  return useQuery({
    queryKey: ['prestazione', prestazioneId],
    queryFn: async () => {
      const result = await findPrestazioniQuery.refetch()
      if (!result.data?.success) return null

      return result.data?.data?.records.find(
        (r) => r.id_interno === prestazioneId
      )
    },
    staleTime: 60 * 10 * 1000,
  })
}
