import { useCallback, useEffect, useRef, useState } from 'react'
import { throttle } from 'lodash-es'
import { initLogger } from 'shared'

export type UseTimeoutCounterOptions = {
  seconds?: number
  postponeOnActivity?: boolean
}

const DEFAULT_OPTS: UseTimeoutCounterOptions = {
  seconds: 8,
  postponeOnActivity: false,
}

const activityEvents = [
  'mousemove',
  'mousedown',
  'resize',
  'keydown',
  'touchstart',
  'wheel',
]

const logger = initLogger('useTimeoutCounter')

export const useTimeoutCounter = () => {
  const intervalRef = useRef<ReturnType<typeof setInterval>>()
  const timeLeftIntervalRef = useRef<ReturnType<typeof setInterval>>()
  const enableArgs = useRef<
    [opts: UseTimeoutCounterOptions, cb?: () => void] | null
  >()
  const [isActive, setActive] = useState(false)
  const [secondsLeft, setSecondsLeft] = useState(0)

  const clearTimers = useCallback(() => {
    clearInterval(intervalRef.current)
    clearInterval(timeLeftIntervalRef.current)
  }, [])

  const calculateSecondsLeft = (endDateTime: number) =>
    Math.round((endDateTime - Date.now()) / 1000)

  const updateSecondsLeft = (endDateTime: number) => {
    setSecondsLeft(calculateSecondsLeft(endDateTime))
  }

  const enable = useCallback(
    (opts?: UseTimeoutCounterOptions, cb?: () => void) => {
      const options = {
        ...DEFAULT_OPTS,
        ...opts,
      } as Required<UseTimeoutCounterOptions>
      logger.debug(options, 'enabled')
      enableArgs.current = [options, cb]

      const ms = options.seconds * 1000
      const endDateTime = Date.now() + ms
      clearTimers()

      setActive(true)
      updateSecondsLeft(endDateTime)

      intervalRef.current = setInterval(() => {
        if (Date.now() >= endDateTime) {
          logger.info('timeout expired')
          clearTimers()
          setActive(false)
          setSecondsLeft(0)
          cb?.()
        }
      }, 200)

      timeLeftIntervalRef.current = setInterval(() => {
        logger.debug('updating seconds left')
        updateSecondsLeft(endDateTime)
      }, 1000)
    },
    [clearTimers]
  )

  const clear = useCallback(() => {
    clearTimers()
    enableArgs.current = null
    setActive(false)
  }, [clearTimers])

  useEffect(() => {
    const handleActivity = throttle(() => {
      if (enableArgs.current?.[0].postponeOnActivity) {
        enable(...enableArgs.current)
      }
    }, 400)

    activityEvents.forEach((ev) => window.addEventListener(ev, handleActivity))

    return () => {
      activityEvents.forEach((ev) =>
        window.removeEventListener(ev, handleActivity)
      )
    }
  }, [enable])

  useEffect(() => clear, [clear])

  return {
    enable,
    clear,
    isActive,
    secondsLeft,
    options: enableArgs.current?.[0],
  }
}
