// In the renderer process
export function blobToBuffer(blob: Blob): Promise<Buffer> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = function () {
      const buf = Buffer.from(this.result as ArrayBuffer)
      resolve(buf)
    }
    reader.onerror = reject
    reader.readAsArrayBuffer(blob)
  })
}
