import dayjs from 'dayjs'
import isTomorrow from 'dayjs/plugin/isTomorrow'
import localizedFormat from 'dayjs/plugin/localizedFormat'

import 'dayjs/locale/it'

dayjs.extend(localizedFormat)
dayjs.extend(isTomorrow)

dayjs.locale('it')

export { dayjs }
