import React, { useMemo } from 'react'

export const Ambulatorio: React.FC<{ value?: string | null }> = ({ value }) => {
  const ambulatorio = useMemo(() => {
    if (!value) return null

    return value.split(',')[0]
  }, [value])

  return <>{ambulatorio}</>
}
