import { useState } from 'react'
import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation } from '@tanstack/react-query'
import { createFileRoute, useNavigate } from '@tanstack/react-router'
import Bluebird from 'bluebird'
import { REGEXP_ONLY_DIGITS_AND_CHARS } from 'input-otp'
import { useForm } from 'react-hook-form'
import z from 'zod'

import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
} from '@/components/ui/form'
import {
  InputOTP,
  InputOTPGroup,
  InputOTPSlot,
} from '@/components/ui/input-otp'
import { Input } from '@/components/ui/input.tsx'
import { SubmitButton } from '@/components/SubmitButton'
import { apiClient, HcFetchError } from '@/apiClient'
import { useSetToken } from '@/features/auth/token.ts'

export const Route = createFileRoute('/getting-started/1-authenticate')({
  component: Authenticate,
})

export default function Authenticate() {
  const form = useForm({
    resolver: zodResolver(
      z.object({
        key: z.string().min(10),
        description: z.string().min(4),
      })
    ),
  })

  const useSetTokenMutation = useSetToken()

  const activateLicense = useMutation({
    mutationFn: () => {
      const { key } = form.getValues()

      return apiClient.api.licenses.activate
        .$post({
          json: {
            key,
            forceActivation: status === 'conflict',
            description: form.getValues().description,
          },
        })
        .then((res) => res.json())
    },
    async onSuccess() {
      setStatus('complete')
    },
    onError(error: HcFetchError) {
      // @ts-expect-error dynamic message
      form.setError('root', { message: error?.data?.message })

      if (error.statusCode === 409) {
        setStatus('conflict')
      }
    },
  })

  // const status = useMemo(() => {
  //   if (form.formState.isSubmitSuccessful) return 'complete'
  //
  //   if (activateLicense.error?.statusCode === 409) return 'conflict'
  //
  //   return 'initial'
  // }, [form.formState.isSubmitSuccessful, activateLicense.error?.statusCode])

  const [status, setStatus] = useState<'complete' | 'conflict' | 'initial'>(
    'initial'
  )

  const navigate = useNavigate()

  const onSubmit = async () => {
    await Bluebird.delay(1000)
    const result = await activateLicense.mutateAsync()
    // @ts-expect-error need to fix type inference
    await useSetTokenMutation.mutateAsync(result?.data?.activation_token)

    navigate({
      to: '/getting-started/2-authentication-completed',
      // @ts-expect-error need to fix type inference
      search: { types: result?.data?.license_types },
    })
  }

  return (
    <>
      {/*status: {status} <br />*/}
      {/*form is valid {JSON.stringify(form.formState.isValid)} <br />*/}
      {/*form errors {JSON.stringify(form.formState.errors)} <br />*/}
      {/*values: {JSON.stringify(form.watch())}*/}
      <div className="container relative flex h-full flex-col items-center justify-center">
        <div>
          <div className="mx-auto flex w-full flex-col justify-center space-y-6 sm:w-[400px]">
            <div className="flex flex-col space-y-2 text-center">
              <h1 className="text-2xl font-semibold tracking-tight">
                Attivazione Licenza Totem
              </h1>
            </div>

            <Form {...form}>
              <form
                className="w-100 flex flex-col space-y-6"
                onSubmit={form.handleSubmit(onSubmit)}
              >
                <FormField
                  control={form.control}
                  name="key"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel className="">
                        Inserire il codice di licenza
                      </FormLabel>
                      <FormControl>
                        <InputOTP
                          className="justify-center"
                          maxLength={10}
                          pattern={REGEXP_ONLY_DIGITS_AND_CHARS}
                          autoFocus
                          disabled={status !== 'initial'}
                          render={({ slots }) => (
                            <InputOTPGroup>
                              {slots.map((slot, index) => (
                                <InputOTPSlot
                                  className="bg-white p-2 text-3xl"
                                  key={index}
                                  {...slot}
                                />
                              ))}{' '}
                            </InputOTPGroup>
                          )}
                          {...field}
                        />
                      </FormControl>
                      {/*<FormDescription>This is your public display name.</FormDescription>*/}
                      {/*<FormMessage />*/}
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="description"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel className="">Descrizione postazione</FormLabel>
                      <FormControl>
                        <Input
                          className="justify-center"
                          minLength={4}
                          readOnly={status !== 'initial'}
                          placeholder="Ex: Totem Radiologia"
                          {...field}
                        />
                      </FormControl>
                      <FormDescription>
                        Inserisci un nome riconoscibile per la postazione
                      </FormDescription>
                      {/*<FormMessage />*/}
                    </FormItem>
                  )}
                />

                {status === 'initial' &&
                  form.formState.errors?.root?.message && (
                    <p className="text-destructive text-sm font-medium">
                      {form.formState.errors.root.message}
                    </p>
                  )}

                {status === 'conflict' && (
                  <>
                    <div className="leading-7">
                      <p className="mb-4">
                        La chiave di licenza che hai inserito è già stata
                        attivata su un altro dispositivo.
                      </p>
                      <p className="mb-4">
                        Proseguendo, la chiave verrà disattivata dal dispositivo
                        precedente e attivata su questo.
                      </p>
                      <p className="mb-1 text-lg font-semibold">
                        Vuoi procedere con il trasferimento della licenza a
                        questo dispositivo?
                      </p>
                    </div>
                  </>
                )}

                {status === 'initial' && (
                  <SubmitButton
                    disabled={!form.formState.isValid}
                    isSubmitting={form.formState.isSubmitting}
                  >
                    Invia
                  </SubmitButton>
                )}

                {status === 'conflict' && (
                  <SubmitButton isSubmitting={form.formState.isSubmitting}>
                    Procedi con il trasferimento
                  </SubmitButton>
                )}
              </form>
            </Form>
          </div>
        </div>
      </div>
      {/*<Navigate*/}
      {/*  to={'/getting-started/2-authentication-completed'}*/}
      {/*  search={{ type: 'elimina_code' }}*/}
      {/*/>*/}
    </>
  )
}
