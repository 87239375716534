import './sentry-instrumentation.ts'

import { StrictMode } from 'react'
import ReactDOM from 'react-dom/client'
import { QueryClientProvider } from '@tanstack/react-query'
import { createRouter, RouterProvider } from '@tanstack/react-router'
import { PostHogConfig } from 'posthog-js'
import { PostHogProvider } from 'posthog-js/react'

import '@fontsource/inter/100.css'
import '@fontsource/inter/200.css'
import '@fontsource/inter/300.css'
import '@fontsource/inter/400.css'
import '@fontsource/inter/500.css'
import '@fontsource/inter/600.css'
import '@fontsource/inter/700.css'
import '@fontsource/inter/800.css'
import '@fontsource/inter/900.css'

import { initServiceWorker } from '@/lib/sw-worker/initServiceWorker.ts'
import { AblyProvider } from '@/features/ably/context.tsx'

import { queryClient } from './queryClient'
// Import the generated route tree
import { routeTree } from './routeTree.gen'

import './assets/main.css'

initServiceWorker()

// Create a new router instance
const router = createRouter({ routeTree })

// Register the router instance for type safety
declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router
  }
}

// Render the app
const rootElement = document.getElementById('app')!

const posthogOptions: Partial<PostHogConfig> = {
  api_host: `${import.meta.env.VITE_API_BASE}/ingest`,
  ui_host: 'https://us.posthog.com',
}

if (!rootElement.innerHTML) {
  const root = ReactDOM.createRoot(rootElement)
  root.render(
    <StrictMode>
      <PostHogProvider
        apiKey={import.meta.env.VITE_POSTHOG_KEY}
        options={posthogOptions}
      >
        <QueryClientProvider client={queryClient}>
          <AblyProvider>
            <RouterProvider router={router} />
          </AblyProvider>
        </QueryClientProvider>
      </PostHogProvider>
    </StrictMode>
  )
}
