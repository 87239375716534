import { useEffect, useMemo } from 'react'
import { createFileRoute } from '@tanstack/react-router'
import { CheckIcon, XIcon } from 'lucide-react'
import { z } from 'zod'

import { BigText } from '@/components/BigText.tsx'
import { DateFormat } from '@/components/date-format.tsx'
import { DefaultLayout } from '@/components/layouts/DefaultLayout.tsx'
import { Money } from '@/components/money.tsx'
import { SelectionButton } from '@/components/SelectionButton.tsx'
import { Spinner } from '@/components/Spinner.tsx'
import posPressGreenBtnImage from '@/assets/images/totem/pos-press-green-btn.png'
import { Ambulatorio } from '@/features/totem-pos/ambulatorio.tsx'
import { usePollPayment } from '@/features/totem-pos/use-fetch-payment.ts'
import { useFindPrestazioni } from '@/features/totem-pos/use-find-prestazioni.ts'
import { useStartPayment } from '@/features/totem-pos/use-start-payment.ts'
import { useSessionTimeout } from '@/routes/totem-eliminacode/$ambulatorioId/-hooks.ts'
import { checkinParametersSchema } from '@/routes/totem-pos/$ospedaleId/-checkin-parameters-schema.ts'
import { SelectInvoiceMethod } from '@/routes/totem-pos/$ospedaleId/-components/select-invoice-method.tsx'

export const Route = createFileRoute('/totem-pos/$ospedaleId/4-payment')({
  component: PaymentStep,
  validateSearch: z.intersection(
    z.object({
      iuv: z.string(),
      paymentId: z.string().optional(),
      status: z
        .enum([
          'summary',
          'payment-not-found',
          'payment-pending',
          'payment-success',
          'payment-error',
        ])
        .optional(),
      note: z.enum(['earlyPayment', 'earlyEvent']).optional(),
    }),
    checkinParametersSchema
  ),
})

export function PaymentStep() {
  const { ospedaleId } = Route.useParams()
  const searchParams = Route.useSearch()
  const { iuv, checkinMethod, checkinValue, paymentId } = searchParams
  const findPrestazioniQuery = useFindPrestazioni({
    ospedaleId,
    searchMethod: checkinMethod,
    searchValue: checkinValue,
  })
  const prestazione = useMemo(() => {
    if (!findPrestazioniQuery.data?.success) return null

    return findPrestazioniQuery.data.data?.records?.find((r) => r.iuv === iuv)
  }, [findPrestazioniQuery.data, iuv])

  const status = searchParams.status ?? 'summary'
  const navigate = Route.useNavigate()
  const { timeoutCounter, startTimeout, resetSession } = useSessionTimeout({
    seconds: 120,
    postponeOnActivity: true,
  })

  useEffect(() => {
    if (status === 'payment-not-found' || status === 'payment-error') {
      startTimeout({ seconds: 15 })
    }
  }, [status])

  const setStatus = (newStatus: typeof status) =>
    navigate({ search: { ...searchParams, status: newStatus } })

  // check if prestazione is found
  useEffect(() => {
    if (findPrestazioniQuery.isSuccess && !prestazione) {
      setStatus('payment-not-found')
    }
  }, [findPrestazioniQuery.isSuccess, prestazione])

  // Start payment
  const startPaymentMutation = useStartPayment({
    onSuccess(response) {
      if (!response.success) return

      navigate({
        search: {
          ...searchParams,
          paymentId: response.data.id,
        },
      })
    },
  })

  useEffect(() => {
    if (
      status === 'payment-pending' &&
      prestazione?.iuv &&
      prestazione?.importo &&
      !paymentId
    ) {
      startPaymentMutation.mutate({
        iuv: prestazione.iuv,
        amount: prestazione.importo,
      })
    }
  }, [prestazione?.importo, prestazione?.iuv, status, paymentId])

  // Poll payment status
  const pollPaymentQuery = usePollPayment(
    { paymentId: paymentId as string },
    { enabled: !!paymentId }
  )
  const payment = pollPaymentQuery.data?.success
    ? pollPaymentQuery.data?.data
    : null

  useEffect(() => {
    if (payment?.status === 'succeeded') {
      setStatus('payment-success')
    }

    if (payment?.status === 'failed') {
      setStatus('payment-error')
    }
  }, [payment?.status])

  const handlePayNowClick = () => setStatus('payment-pending')

  return (
    <>
      <DefaultLayout.Body className="items-center justify-center">
        <div className="flex size-full items-center justify-between border bg-violet-50/60 p-20 backdrop-blur">
          {status === 'summary' && (
            <>
              {findPrestazioniQuery.isPending && <Spinner />}
              {findPrestazioniQuery.isSuccess && (
                <>
                  <div>
                    <BigText size="lg" className="font-bold">
                      <Ambulatorio value={prestazione?.ambulatorio} />
                    </BigText>
                    <BigText size="md">
                      <DateFormat
                        date={prestazione?.data_convocazione}
                        format="dddd DD MMMM YYYY [alle] HH:mm"
                        todayFormat="[oggi alle] HH:mm"
                        tomorrowFormat="[domani alle] HH:mm"
                      />
                    </BigText>
                  </div>
                  <BigText>
                    <Money value={prestazione?.importo} />
                  </BigText>
                </>
              )}
            </>
          )}

          {status === 'payment-not-found' && (
            <div className="flex flex-col gap-4">
              <XIcon className="mx-auto mb-4 size-36 text-red-600" />
              <BigText size="lg" className="text-center font-bold">
                Spiacenti, non é stata trovata nessuna prenotazione
              </BigText>
            </div>
          )}

          {status === 'payment-pending' && (
            <div className="flex items-center">
              <div className="basis-2/3">
                <BigText size="lg" className="font-normal">
                  <div className="mb-10">
                    Premi il <span className="font-bold">tasto verde</span> sul{' '}
                    <span className="font-bold">POS</span> per iniziare
                  </div>
                  <div>
                    e segui le istruzioni sullo{' '}
                    <span className="font-bold">schermo</span> del{' '}
                    <span className="font-bold">POS</span> per completare il
                    pagamento
                  </div>

                  {/*<div className="mb-10">Per procedere al pagamento:</div>*/}
                  {/*<ol className="list-inside list-decimal space-y-10">*/}
                  {/*  <li>*/}
                  {/*    premi il <span className="font-bold">tasto verde</span>{' '}*/}
                  {/*    sul POS*/}
                  {/*  </li>*/}
                  {/*  <li>*/}
                  {/*    segui le <span className="font-bold">istruzioni</span> che*/}
                  {/*    compariranno sullo schermo del POS*/}
                  {/*  </li>*/}
                  {/*</ol>*/}
                </BigText>
              </div>
              <div>
                <img className="w-full" src={posPressGreenBtnImage} />
              </div>
            </div>
          )}

          {status === 'payment-error' && (
            <div className="flex w-full flex-col items-center">
              <XIcon className="size-36 text-red-600" />
              <BigText className="flex text-center">
                {'Errore durante il pagamento'}
              </BigText>
            </div>
          )}

          {status === 'payment-success' && (
            <div className="flex w-full gap-x-20">
              <div className="flex w-full basis-4/12 flex-col items-center justify-center">
                <CheckIcon className="mx-auto size-36 text-green-600" />
                <BigText
                  className="text-center !leading-relaxed"
                  data-testId="status-message"
                >
                  Pagamento effettuato
                </BigText>
              </div>

              <div className="basis-8/12">
                {prestazione && (
                  <SelectInvoiceMethod
                    ospedaleId={ospedaleId}
                    prestazioneId={prestazione.id_interno!}
                    checkinMethod="IUV"
                    checkinValue={iuv}
                  />
                )}
              </div>
            </div>
          )}
        </div>
      </DefaultLayout.Body>
      <DefaultLayout.Footer
        className="flex justify-between"
        backBtnVisible={true}
        timeoutCounter={timeoutCounter}
        backBtnLabel="Annulla"
        onBackBtnClick={resetSession}
        {...(status === 'payment-success' && {
          backBtnVisible: false,
          backBtnLabel: 'Ricomincia',
        })}
        {...((status === 'payment-pending' ||
          status === 'payment-error' ||
          status === 'payment-not-found') /*&& !isRetriablePaymentError*/ && {
          backBtnLabel: 'Annulla Pagamento',
        })}
      >
        <div>
          {status === 'summary' && prestazione && (
            <SelectionButton
              className="block px-10 text-center"
              size="md"
              data-testId="confirmation-btn"
              onClick={handlePayNowClick}
            >
              Paga Adesso
            </SelectionButton>
          )}
        </div>
      </DefaultLayout.Footer>
    </>
  )
}
