import React, { useMemo } from 'react'
import dayjs from 'dayjs'

interface DateFormatProps {
  date?: string | Date // Optional date property
  format?: string // Optional format for dates that are neither today nor tomorrow
  todayFormat?: string // Optional format or text for "today"
  tomorrowFormat?: string // Optional format or text for "tomorrow"
}

export const DateFormat: React.FC<DateFormatProps> = ({
  date,
  format = 'MMMM D, YYYY',
  todayFormat = '[oggi]', // Default "today" text
  tomorrowFormat = '[domani]', // Default "tomorrow" text
}) => {
  const displayDate = useMemo(() => {
    if (!date) return null // Return null if no date is provided

    const inputDate = dayjs(date)
    const today = dayjs()
    const tomorrow = dayjs().add(1, 'day')

    if (inputDate.isSame(today, 'day')) {
      return today.format(todayFormat) // Use todayFormat to format today's date
    } else if (inputDate.isSame(tomorrow, 'day')) {
      return tomorrow.format(tomorrowFormat) // Use tomorrowFormat to format tomorrow's date
    } else {
      return inputDate.format(format) // Use the passed format for other dates
    }
  }, [date, format, todayFormat, tomorrowFormat])

  // If no date or displayDate is null, render nothing
  if (!displayDate) return null

  return <span>{displayDate}</span>
}
