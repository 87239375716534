import {
  queryOptions,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'

import { bus } from '@/lib/bus.ts'

export const getTokenQueryOptions = () => {
  return queryOptions({
    queryKey: ['token'],
    queryFn: async () => bus.getToken(),
  })
}

export const useGetToken = () => {
  return useQuery(getTokenQueryOptions())
}

export const useSetToken = () => {
  const queryClient = useQueryClient()

  return useMutation<unknown, unknown, string>({
    mutationFn: (token) => bus.setToken(token),
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: ['token'] })
    },
  })
}
