import { ReactNode, useEffect, useRef, useState } from 'react'

import { cn } from '@/lib/utils.ts'

interface ScrollAreaWithShadowsProps {
  children: ReactNode
  className?: string // Optional className prop
}

export const ScrollAreaWithShadows: React.FC<ScrollAreaWithShadowsProps> = ({
  children,
  className,
}) => {
  const scrollRef = useRef<HTMLDivElement | null>(null)
  const [showTopShadow, setShowTopShadow] = useState(false)
  const [showBottomShadow, setShowBottomShadow] = useState(true)

  const handleScroll = () => {
    const element = scrollRef.current
    if (!element) return

    const atTop = element.scrollTop === 0
    const atBottom =
      element.scrollTop + element.clientHeight >= element.scrollHeight

    setShowTopShadow(!atTop)
    setShowBottomShadow(!atBottom)
  }

  useEffect(() => {
    const element = scrollRef.current
    if (element) {
      element.addEventListener('scroll', handleScroll)
      handleScroll() // Call it initially to set the shadows

      return () => {
        element.removeEventListener('scroll', handleScroll)
      }
    }
  }, [])

  return (
    <div className="relative h-full">
      {/* Top shadow */}
      {/*{showTopShadow && (*/}
      {/*    <div*/}
      {/*        className="pointer-events-none absolute inset-x-0 top-0 z-10 h-14 bg-gradient-to-b from-gray-400 to-transparent transition"/>*/}
      {/*)}*/}
      <div
        className={cn(
          'pointer-events-none absolute inset-x-0 top-0 z-10 h-14 bg-gradient-to-b from-gray-400 to-transparent transition',
          showTopShadow ? 'opacity-100' : 'opacity-0'
        )}
      />

      {/* Custom scrollable container */}
      <div
        ref={scrollRef}
        className={cn('relative overflow-y-auto', className)}
      >
        {children}
      </div>

      {/* Bottom shadow */}

      <div
        className={cn(
          'pointer-events-none absolute inset-x-0 bottom-0 z-10 h-14 bg-gradient-to-t from-gray-400 to-transparent transition',
          showBottomShadow ? 'opacity-100' : 'opacity-0'
        )}
      />
    </div>
  )
}
