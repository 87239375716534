import { createFileRoute, Link } from '@tanstack/react-router'
import { ChevronRightIcon } from 'lucide-react'

import { fiscalCodeRegex } from '@/lib/fiscal-code.ts'
import { pagoPaRegex, ricettaRegex } from '@/lib/pagopa-regex.ts'
import { useCardScan } from '@/hooks/useCardScan.ts'
import { useUpdateApp } from '@/hooks/useUpdateApp.ts'
import { BigText } from '@/components/BigText.tsx'
import { DefaultLayout } from '@/components/layouts/DefaultLayout.tsx'
import { SelectionButton } from '@/components/SelectionButton.tsx'

export const Route = createFileRoute('/totem-pos/$ospedaleId/1-instructions')({
  component: InstructionsStep,
})

export function InstructionsStep() {
  const params = Route.useParams()
  const navigate = Route.useNavigate()

  useCardScan({
    onScan(input, matchedPattern) {
      if (!matchedPattern) return null

      if (matchedPattern === pagoPaRegex) {
        const { iuv } = matchedPattern.exec(input)!.groups as { iuv: string }

        navigate({
          to: '/totem-pos/$ospedaleId/4-payment',
          params,
          search: { iuv, checkinMethod: 'IUV', checkinValue: iuv },
        })
      }

      if (matchedPattern === fiscalCodeRegex) {
        const codiceFiscale = matchedPattern.exec(input)?.groups?.fiscalCode

        navigate({
          to: '/totem-pos/$ospedaleId/3-select-service',
          params,
          search: { checkinMethod: 'CF', checkinValue: codiceFiscale! },
        })
      }

      if (matchedPattern === ricettaRegex) {
        const ricetta = ricettaRegex.exec(input)?.groups?.ricetta

        navigate({
          to: '/totem-pos/$ospedaleId/3-select-service',
          params,
          search: { checkinMethod: 'RICETTA', checkinValue: ricetta! },
        })
      }
    },
    patterns: [pagoPaRegex, fiscalCodeRegex, ricettaRegex],
  })

  useUpdateApp()

  return (
    <>
      <DefaultLayout.Header>
        <BigText>Selezionare la modalità di accettazione</BigText>
      </DefaultLayout.Header>

      <DefaultLayout.Body className="justify-center">
        {/*Main grid*/}
        <div className="flex h-full flex-col justify-around gap-y-20 border-gray-300">
          {/*Yes*/}

          <Link
            className="flex size-full max-h-60"
            to="/totem-pos/$ospedaleId/2-scan"
            params={params}
            search={{ scanType: 'bollettino-pagopa' }}
          >
            <SelectionButton className="flex h-full flex-row items-center justify-between font-bold uppercase">
              Bollettino PagoPa (SOLO PAGANTI)
              <ChevronRightIcon className="size-20" />
            </SelectionButton>
          </Link>

          <Link
            className="flex size-full max-h-60"
            to="/totem-pos/$ospedaleId/2-scan"
            params={params}
            search={{ scanType: 'numero-ricetta' }}
          >
            <SelectionButton className="flex h-full flex-row items-center justify-between font-bold uppercase">
              Numero Ricetta Medica
              <ChevronRightIcon className="size-20" />
            </SelectionButton>
          </Link>

          <Link
            className="flex size-full max-h-60"
            to="/totem-pos/$ospedaleId/2-manual-input"
            params={params}
            search={{ inputType: 'PRENOTAZIONE' }}
          >
            <SelectionButton className="flex h-full flex-row items-center justify-between font-bold uppercase">
              Numero prenotazione
              <ChevronRightIcon className="size-20" />
            </SelectionButton>
          </Link>

          <Link
            className="flex size-full max-h-60"
            to="/totem-pos/$ospedaleId/2-scan"
            params={params}
            search={{ scanType: 'id-card-o-tess-sanitaria' }}
          >
            <SelectionButton className="flex h-full flex-row items-center justify-between font-bold uppercase">
              Codice Fiscale o Tessera Sanitaria
              <ChevronRightIcon className="size-20" />
            </SelectionButton>
          </Link>
        </div>
      </DefaultLayout.Body>

      {/*<DefaultLayout.Footer backBtnVisible={true} />*/}
    </>
  )
}
