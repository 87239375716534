import { useCallback, useEffect } from 'react'
import { useNavigate, useParams } from '@tanstack/react-router'

import {
  useTimeoutCounter,
  UseTimeoutCounterOptions,
} from '@/hooks/useTimeoutCounter.ts'

export type UseSessionTimeoutOptions = UseTimeoutCounterOptions & {
  enabled?: boolean
}

export const useSessionTimeout = (options: UseSessionTimeoutOptions) => {
  const params = useParams({ strict: false })
  const navigate = useNavigate()
  const { enabled, ...timeoutOptions } = {
    enabled: true,
    sessionType: 'eliminacode',
    ...options,
  }
  // const params = route.useParams()
  // const navigate = route.useNavigate()
  const timeoutCounter = useTimeoutCounter()
  const resetSession = useCallback(
      () => navigate({ to: '/', params }),
      [navigate, params]
  )
  const startTimeout = useCallback(
      (to?: Partial<typeof timeoutOptions>) =>
          timeoutCounter.enable({ ...timeoutOptions, ...to }, () => resetSession()),
      [resetSession, JSON.stringify(timeoutOptions)]
  )

  useEffect(() => {
    if (enabled) {
      startTimeout()
    }
  }, [enabled])

  return { timeoutCounter, startTimeout, resetSession }
}