import React from 'react'
import { pdf } from '@react-pdf/renderer'
import { useMutation } from '@tanstack/react-query'

import { bus } from '@/lib/bus.ts'
import { blobToBuffer } from '@/lib/pdf.ts'

export const usePrintPdf = () => {
  return useMutation<void, Error, { template: React.ReactElement }>({
    mutationKey: ['print-pdf'],
    mutationFn: async (variables) => {
      const pdfBlob = await pdf(variables.template).toBlob()
      const contentBuffer = await blobToBuffer(pdfBlob)

      return bus.sendPdfBufferToPrint(contentBuffer)
    },
  })
}
