import { useEffect } from 'react'
import { createFileRoute, Link, useNavigate } from '@tanstack/react-router'

import { BigText } from '@/components/BigText'
import { DefaultLayout } from '@/components/layouts/DefaultLayout'
import { SelectionButton } from '@/components/SelectionButton'
import { useConfig } from '@/features/kiosk/store.ts'
import { useKioskStatus } from '@/features/kiosk/useKioskStatus.ts'
import { useLicense } from '@/features/license/license.ts'
import { useTotemEliminacode } from '@/features/totem-eliminacode/use-totem-eliminacode.ts'

// import { queryClient } from '@/queryClient.ts'

export const Route = createFileRoute('/')({
  // beforeLoad: async () => {
  //   await queryClient.ensureQueryData(getTokenQueryOptions())
  // },
  component: Index,
})

export default function Index() {
  const { status } = useKioskStatus({ performRedirect: true })
  const licenseQuery = useLicense({ enabled: status === 'ready' })
  const licenseTypes = licenseQuery.data?.success
    ? licenseQuery.data?.data?.types
    : []
  const configQuery = useConfig()
  const config = configQuery.data
  const ambulatorioId = config?.ambulatorioId as string

  const navigate = useNavigate()

  useEffect(() => {
    if (
      licenseQuery.isPending ||
      configQuery.isPending ||
      !config ||
      status !== 'ready'
    ) {
      return
    }

    if (licenseTypes.length === 1) {
      const type = licenseTypes[0]
      if (type === 'eliminacode') {
        navigate({
          to: '/totem-eliminacode/$ambulatorioId',
          params: { ambulatorioId: config.ambulatorioId! },
          replace: true,
        })
      }
      if (type === 'ticket') {
        navigate({
          to: '/totem-pos/$ospedaleId',
          params: { ospedaleId: config.ospedaleId! },
          replace: true,
        })
      }
    }
  }, [
    config,
    configQuery.isPending,
    licenseQuery.isPending,
    licenseTypes,
    status,
  ])

  const eliminacodeQuery = useTotemEliminacode(ambulatorioId, {
    enabled: !!ambulatorioId && licenseTypes.includes('eliminacode'),
  })
  const eliminacode = eliminacodeQuery.data

  const ambulatorioValid = eliminacode && eliminacode?.esito === 'OK'

  return (
    <>
      <DefaultLayout
        isLoading={
          licenseQuery.isLoading ||
          eliminacodeQuery.isLoading ||
          configQuery.isLoading
        }
      >
        {ambulatorioValid && (
          <>
            <DefaultLayout.Header>
              <BigText>
                Buongiorno. <br />
                Prego selezionare il servizio
              </BigText>
            </DefaultLayout.Header>

            <DefaultLayout.Body>
              <div className="flex grow flex-col space-y-20">
                <SelectionButton className="max-h-60 uppercase" asChild>
                  <Link
                    to={'/totem-eliminacode/$ambulatorioId'}
                    params={{ ambulatorioId }}
                  >
                    Rilascio Ticket
                  </Link>
                </SelectionButton>

                <SelectionButton className="max-h-60 uppercase">
                  Prenotazione
                </SelectionButton>
              </div>
            </DefaultLayout.Body>
          </>
        )}

        {!ambulatorioValid && (
          <>
            <div className="flex size-full items-center justify-center text-2xl">
              Configurazione non valida. Rivolgersi all&apos;amministratore del
              sistema
            </div>
          </>
        )}
      </DefaultLayout>
    </>
  )
}
