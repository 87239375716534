import React, { useMemo } from 'react'

export const Money: React.FC<{ value?: string | number }> = ({ value }) => {
  return useMemo(() => {
    if (isNaN(value as number)) return null

    return new Intl.NumberFormat('it-IT', {
      style: 'currency',
      currency: 'EUR',
    }).format(Number(value))
  }, [value])
}
