import { useEffect, useMemo } from 'react'
import { zodResolver } from '@hookform/resolvers/zod'
import { createFileRoute } from '@tanstack/react-router'
import { useForm } from 'react-hook-form'
import { z } from 'zod'

import { extractFiscalCode } from '@/lib/fiscal-code.ts'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
} from '@/components/ui/form.tsx'
import { BigText } from '@/components/BigText.tsx'
import {
  KeyboardInput,
  KeyboardInputProps,
} from '@/components/keyboard-input.tsx'
import { DefaultLayout } from '@/components/layouts/DefaultLayout.tsx'
import { SelectionButton } from '@/components/SelectionButton.tsx'
import { useFindPrestazioni } from '@/features/totem-pos/use-find-prestazioni.ts'
import { useSessionTimeout } from '@/routes/totem-eliminacode/$ambulatorioId/-hooks.ts'
import { checkinParametersSchema } from '@/routes/totem-pos/$ospedaleId/-checkin-parameters-schema.ts'

export const Route = createFileRoute('/totem-pos/$ospedaleId/2-manual-input')({
  component: ManualInputStep,
  validateSearch: z.object({
    inputType: checkinParametersSchema.shape.checkinMethod,
  }),
})

const formSchema = z.union([
  z.object({
    inputType: z.literal('RICETTA'),
    input: z.coerce.number().int().gte(10000),
  }),
  z.object({
    inputType: z.literal('PRENOTAZIONE'),
    input: z.coerce.number().int().gte(10000000).lte(99999999),
  }),
  z.object({
    inputType: z.literal('CF'),
    input: z
      .string()
      .refine((value) => extractFiscalCode(value), 'Codice fiscale non valido'),
  }),
  z.object({
    inputType: z.literal('IUV'),
    input: z.coerce.number(),
  }),
])

export function ManualInputStep() {
  const { ospedaleId } = Route.useParams()
  const searchParams = Route.useSearch()
  const { inputType } = searchParams
  const navigate = Route.useNavigate()
  const startOver = () => navigate({ to: '/' })
  const { timeoutCounter } = useSessionTimeout({
    seconds: 60,
    postponeOnActivity: true,
  })

  const label = useMemo(() => {
    const labelSwitch: Record<typeof inputType, string> = {
      CF: 'il Codice Fiscale',
      PRENOTAZIONE: 'il Numero Prenotazione',
      RICETTA: 'il Numero Ricetta',
      IUV: 'Codice PagoPA',
    }

    return `Inserisci ${labelSwitch[inputType]}`
  }, [inputType])

  // form
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    reValidateMode: 'onChange',
  })

  useEffect(() => {
    // @ts-expect-error I know
    form.reset({ ...form.getValues(), inputType })
  }, [form, inputType])

  const fetchPrestazioniQuery = useFindPrestazioni(
    {
      ospedaleId,
      searchMethod: inputType,
      searchValue: String(form.getValues().input),
    },
    { enabled: false }
  )

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    await fetchPrestazioniQuery.refetch()

    navigate({
      to: '/totem-pos/$ospedaleId/3-select-service',
      search: {
        ...searchParams,
        checkinMethod: inputType,
        checkinValue: String(values.input),
      },
    })
  }

  const {
    keyboardType,
    keyboardLayout,
  }: Pick<KeyboardInputProps, 'keyboardLayout' | 'keyboardType'> =
    useMemo(() => {
      if (inputType === 'CF')
        return {
          keyboardLayout: {
            default: [
              '1 2 3 4 5 6 7 8 9 0 {bksp}',
              '{d} Q W E R T Y U I O P',
              '{d} {d} A S D F G H J K L',
              '{d} {d} {d} Z X C V B N M , .',
              '{space}',
            ],
            shift: [
              '1 2 3 4 5 6 7 8 9 0 {bksp}',
              '{d} Q W E R T Y U I O P',
              '{d} {d} A S D F G H J K L',
              '{d} {d} {d} Z X C V B N M , .',
              '{space}',
            ],
          },
        }

      if (
        inputType === 'PRENOTAZIONE' ||
        inputType === 'IUV' ||
        inputType == 'RICETTA'
      ) {
        return { keyboardType: 'numeric' }
      }

      return { keyboardType: 'full' }
    }, [inputType])

  return (
    <>
      <DefaultLayout.Body className="items-center justify-center">
        <BigText className="mb-8" size="sm">
          {label}
        </BigText>

        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmit)}
            className="flex w-full max-w-6xl items-center gap-x-10"
          >
            <div className="grow">
              <FormField
                control={form.control}
                name="input"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <KeyboardInput
                        {...field}
                        keyboardType={keyboardType}
                        keyboardLayout={keyboardLayout}
                        autoFocus
                        autoComplete="off"
                        onChange={field.onChange}
                        className="p-10 sm:text-lg md:text-xl lg:text-2xl xl:text-3xl 2xl:text-4xl"
                      />
                    </FormControl>
                    {/*<FormDescription>*/}
                    {/*  This is your public display name.*/}
                    {/*</FormDescription>*/}
                    {/*<FormMessage />*/}
                  </FormItem>
                )}
              />
            </div>
            <div className="grow-0">
              <SelectionButton
                className="h-[82px] min-w-40 items-center"
                size="sm"
                disabled={
                  !form.formState.isValid || fetchPrestazioniQuery.isFetching
                }
              >
                {fetchPrestazioniQuery.isFetching ? 'Un attimo' : 'Invia'}
              </SelectionButton>
            </div>
          </form>
        </Form>
      </DefaultLayout.Body>

      <DefaultLayout.Footer
        backBtnVisible={true}
        backBtnLabel="Ricomincia"
        onBackBtnClick={startOver}
        timeoutCounter={timeoutCounter}
        className="grid grid-cols-3 justify-around"
      />
    </>
  )
}
