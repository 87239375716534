import React from 'react'
import { Document, Page, StyleSheet, Text, View } from '@react-pdf/renderer'

// const msgs = [
//   'RAGUSA-RADIOLOGIA GIOVANNI PAOLO II',
//   'Data: 20/03/2024 01:03',
//   'Numero pren.: 4833',
// ]

export const Ticket: React.FC<{ messages: Array<string> }> = ({ messages }) => (
  <Document>
    <Page size={[220]}>
      <View style={styles.header}>
        <Text>ASP Ragusa</Text>
      </View>

      <View style={styles.body}>
        {messages.map((m, index) => (
          // m?.match(/\w+|\W+/g)?.map((seg, i) => (
          //   <Text style={styles.bodyTextMd} key={i}>
          //     {seg}
          //   </Text>

          // ))
          <Text style={styles.bodyTextMd} key={index}>
            {m}
          </Text>
        ))}
      </View>

      <View style={styles.footer}>
        <Text style={styles.footerText}>Arrivo: 14:45</Text>
      </View>
    </Page>
  </Document>
)

const styles = StyleSheet.create({
  header: {
    fontSize: 18,
    padding: 10,
    textAlign: 'center',
    fontFamily: 'Helvetica-Bold',
  },
  body: {
    textAlign: 'center',
    padding: 10,
    borderTop: '1px dashed gray',
    borderBottom: '1px dashed gray',
  },
  bodyTextMd: {
    fontSize: 14,
    marginBottom: 5,
  },
  footer: {
    textAlign: 'center',
    padding: 10,
  },
  footerText: {
    fontSize: 12,
  },
})
