import { queryOptions, useQuery } from '@tanstack/react-query'

import { apiClient } from '../../apiClient.ts'

export const totemEliminacodeQueryOptions = (ambulatorioId?: string) =>
  queryOptions({
    queryKey: ['eliminacode', 'activate', ambulatorioId],
    queryFn: () =>
      apiClient.api['totem-eliminacode'][':ambulatorioId']
        .$get({
          param: { ambulatorioId: ambulatorioId! },
        })
        .then((r) => r.json()),
  })

export const useTotemEliminacode = (
  ambulatorioId?: string,
  options?: Partial<ReturnType<typeof totemEliminacodeQueryOptions>>
) =>
  useQuery({
    ...totemEliminacodeQueryOptions(ambulatorioId),
    ...options,
  })
