import { useEffect } from 'react'
import { createFileRoute } from '@tanstack/react-router'
import { z } from 'zod'

import { usePrintPdf } from '@/hooks/use-print-pdf.tsx'
import { BigText } from '@/components/BigText.tsx'
import { DefaultLayout } from '@/components/layouts/DefaultLayout.tsx'
import { useGetPrestazione } from '@/features/totem-pos/use-get-prestazione.ts'
import { useSessionTimeout } from '@/routes/totem-eliminacode/$ambulatorioId/-hooks.ts'
import { checkinParametersSchema } from '@/routes/totem-pos/$ospedaleId/-checkin-parameters-schema.ts'

import { Receipt } from '../-components/receipt.tsx'

export const Route = createFileRoute(
  '/totem-pos/$ospedaleId/5-send-invoice/print'
)({
  component: InvoicePrint,
  validateSearch: z.intersection(
    checkinParametersSchema,
    z.object({
      prestazioneId: z.string(),
    })
  ),
})

function InvoicePrint() {
  const { ospedaleId } = Route.useParams()
  const { checkinMethod, checkinValue, prestazioneId } = Route.useSearch()
  const navigate = Route.useNavigate()
  const findPrestazioniQuery = useGetPrestazione({
    ospedaleId,
    prestazioneId: prestazioneId!,
    searchMethod: checkinMethod,
    searchValue: checkinValue,
  })
  const prestazione = findPrestazioniQuery.data
  const printPdfMutation = usePrintPdf()

  const { timeoutCounter } = useSessionTimeout({
    seconds: 10,
    enabled: false,
  })

  useEffect(() => {
    if (prestazione) {
      printPdfMutation.mutate({
        template: <Receipt items={[prestazione!]} />,
      })
    }
  }, [prestazione, prestazione])

  useEffect(() => {
    if (printPdfMutation.isSuccess) {
      navigate({
        to: '/totem-pos/$ospedaleId/6-success',
        search: { message: 'Ritirare la ricevuta. grazie' },
      })
    }
  }, [printPdfMutation.isSuccess])

  return (
    <>
      <DefaultLayout.Body>
        <div className="flex grow flex-col items-center justify-center">
          {printPdfMutation.isPending && (
            <>
              <BigText>Stampa della ricevuta in corso...</BigText>
            </>
          )}
        </div>
      </DefaultLayout.Body>

      <DefaultLayout.Footer timeoutCounter={timeoutCounter} />
    </>
  )
}
