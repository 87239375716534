import React from 'react'
import { useNavigate } from '@tanstack/react-router'
import { ChevronRightIcon } from 'lucide-react'

import { SelectionButton } from '@/components/SelectionButton.tsx'
import { CheckinParametersSchema } from '@/routes/totem-pos/$ospedaleId/-checkin-parameters-schema.ts'

type SelectInvoiceMethodProps = {
  ospedaleId: string
  prestazioneId: string
} & Pick<CheckinParametersSchema, 'checkinMethod' | 'checkinValue'>

export const SelectInvoiceMethod: React.FC<SelectInvoiceMethodProps> = ({
  ospedaleId,
  checkinValue,
  checkinMethod,
  prestazioneId,
}) => {
  const navigate = useNavigate({
    from: '/totem-pos/$ospedaleId/5-send-invoice/$invoiceMode',
  })

  const selectInvoiceMethod = (method: string | 'noInvoice') => {
    if (method === 'noInvoice') {
      return navigate({
        to: `/totem-pos/$ospedaleId/6-success`,
        params: { ospedaleId },
      })
    }

    navigate({
      to: `/totem-pos/$ospedaleId/5-send-invoice/${method}`,
      params: { ospedaleId },
      search: { prestazioneId, checkinMethod, checkinValue },
    })
  }

  return (
    <>
      <div className="flex size-full flex-col justify-around gap-y-20 border-gray-300">
        {/*<SelectionButton*/}
        {/*  className="flex max-h-60 flex-row items-center justify-between uppercase"*/}
        {/*  onClick={() => selectInvoiceMethod('print')}*/}
        {/*>*/}
        {/*  /!*<div className="flex flex-col items-start">*!/*/}
        {/*  /!*  <BigText className="uppercase text-white">Stampa</BigText>*!/*/}
        {/*  /!*</div>*!/*/}
        {/*  Stampa ricevuta*/}
        {/*  <ChevronRightIcon className="size-20" />*/}
        {/*</SelectionButton>*/}

        <SelectionButton
          className="flex max-h-60 flex-row items-center justify-between uppercase"
          onClick={() => selectInvoiceMethod('sms')}
        >
          Invia fattura per SMS
          <ChevronRightIcon className="size-20" />
        </SelectionButton>

        <SelectionButton
          className="flex max-h-60 flex-row items-center justify-between uppercase"
          onClick={() => selectInvoiceMethod('email')}
        >
          Invia fattura per Email
          <ChevronRightIcon className="size-20" />
        </SelectionButton>

        {/*<SelectionButton*/}
        {/*  className="flex max-h-60 flex-row items-center justify-between uppercase"*/}
        {/*  variant="muted"*/}
        {/*  onClick={() => selectInvoiceMethod('noInvoice')}*/}
        {/*>*/}
        {/*  Stampa riepilogo fattura*/}
        {/*</SelectionButton>*/}
      </div>
    </>
  )
}
