import { createFileRoute } from '@tanstack/react-router'
import { z } from 'zod'

import { BigText } from '@/components/BigText.tsx'
import { DefaultLayout } from '@/components/layouts/DefaultLayout.tsx'
import { useSessionTimeout } from '@/routes/totem-eliminacode/$ambulatorioId/-hooks.ts'
import { checkinParametersSchema } from '@/routes/totem-pos/$ospedaleId/-checkin-parameters-schema.ts'
import { SelectInvoiceMethod } from '@/routes/totem-pos/$ospedaleId/-components/select-invoice-method.tsx'

export const Route = createFileRoute('/totem-pos/$ospedaleId/5-send-invoice/')({
  component: SendInvoiceStep,
  validateSearch: z.intersection(
    checkinParametersSchema,
    z.object({
      prestazioneId: z.string(),
    })
  ),
})

export function SendInvoiceStep() {
  const { ospedaleId } = Route.useParams()
  const searchParams = Route.useSearch()

  const { timeoutCounter } = useSessionTimeout({
    seconds: 30,
    postponeOnActivity: true,
  })

  return (
    <>
      <DefaultLayout.Header>
        <BigText>Come vuoi ricevere la ricevuta?</BigText>
      </DefaultLayout.Header>

      <DefaultLayout.Body className="justify-center">
        <SelectInvoiceMethod
          ospedaleId={ospedaleId}
          {...searchParams}
          prestazioneId={searchParams.prestazioneId!}
        />
      </DefaultLayout.Body>

      <DefaultLayout.Footer
        timeoutCounter={timeoutCounter}
        className="grid grid-cols-3 justify-around"
      />
    </>
  )
}
