import { useEffect, useMemo } from 'react'
import { useMutation } from '@tanstack/react-query'
import { createFileRoute } from '@tanstack/react-router'
import Bluebird from 'bluebird'
import { CheckIcon, ChevronLeft, XIcon } from 'lucide-react'
import { z } from 'zod'

import { usePrintPdf } from '@/hooks/use-print-pdf.tsx'
import { Button } from '@/components/ui/button.tsx'
import { BigText } from '@/components/BigText.tsx'
import { DefaultLayout } from '@/components/layouts/DefaultLayout.tsx'
import { apiClient } from '@/apiClient.ts'
import { Ticket } from '@/routes/-Ticket.tsx'
import { useSessionTimeout } from '@/routes/totem-eliminacode/$ambulatorioId/-hooks.ts'

export const Route = createFileRoute(
  '/totem-eliminacode/$ambulatorioId/5-printing-ticket'
)({
  component: PrintingTicketStep,
  validateSearch: z.object({
    codaId: z.string(),
    paramCoda: z.string().optional(),
    paramValue: z.string().optional(),
  }),
})

export function PrintingTicketStep() {
  const params = Route.useParams()
  const { ambulatorioId } = params
  const { codaId, paramValue } = Route.useSearch()

  const addToQueueMutation = useMutation({
    mutationKey: ['addToQueue'],
    mutationFn: async () => {
      const [result] = await Bluebird.all([
        apiClient.api['totem-eliminacode'][':ambulatorioId'].queue
          .$post({
            param: { ambulatorioId },
            json: {
              codaId,
              paramCodaValue: paramValue,
            },
          })
          .then((r) => r.json()),
        Bluebird.delay(1000),
      ])

      return result
    },
    async onSuccess(data) {
      if (data.success && data.data.esito === 'OK') {
        await printingTicketMutation.mutateAsync(data)
      }
    },
  })

  useEffect(() => {
    addToQueueMutation.mutate()
  }, [])

  const formattedMessaggioPren = useMemo(
    () =>
      addToQueueMutation.data?.success
        ? addToQueueMutation.data?.data?.records?.[0]?.messaggio_pren?.split(
            '\r'
          ) ?? []
        : [],
    [addToQueueMutation.data]
  )

  const printPdfMutation = usePrintPdf()

  const printingTicketMutation = useMutation<
    null,
    Error,
    typeof addToQueueMutation.data
  >({
    mutationKey: ['print-ticket'],
    mutationFn: async (variables) => {
      if (!variables?.success) return null
      const data = variables?.data

      const formattedMessaggioPren =
        data?.esito === 'OK'
          ? data?.records?.[0]?.messaggio_pren?.split('\r') ?? []
          : []

      await printPdfMutation.mutateAsync({
        template: <Ticket messages={formattedMessaggioPren} />,
      })

      return null
    },
  })

  const status = useMemo(() => {
    if (addToQueueMutation.isError || printingTicketMutation.isError) {
      return 'error'
    }
    if (addToQueueMutation.isSuccess && printingTicketMutation.isSuccess) {
      return 'success'
    }

    if (
      addToQueueMutation.isPending ||
      printingTicketMutation.isPending ||
      (addToQueueMutation.isIdle && printingTicketMutation.isIdle)
    ) {
      return 'pending'
    }

    if (
      addToQueueMutation.isSuccess &&
      addToQueueMutation?.data?.success &&
      addToQueueMutation?.data?.data?.esito !== 'OK'
    ) {
      const esito = addToQueueMutation.data.data.esito
      if (/NOK2.+Già.+Prenotato/gi.test(esito)) {
        return 'queueError.alreadyInQueue'
      }

      return 'queueError.generic'
    }

    return null
  }, [
    addToQueueMutation.data,
    addToQueueMutation.isError,
    addToQueueMutation.isPending,
    addToQueueMutation.isSuccess,
    printingTicketMutation.isPending,
    printingTicketMutation.isError,
    printingTicketMutation.isSuccess,
  ])

  const { timeoutCounter, resetSession } = useSessionTimeout({
    enabled: status !== 'pending',
    seconds: 8,
    postponeOnActivity: false,
  })

  return (
    <>
      <DefaultLayout.Body>
        {/*{JSON.stringify({ status })} <br />*/}
        {/*{JSON.stringify(addToQueueMutation)} <br />*/}
        {/*{JSON.stringify(printingTicketMutation)} <br />*/}
        {status === 'pending' && (
          <div className="flex grow flex-col items-center justify-center">
            <BigText data-testId="status-message">
              Stampa del ticket in corso...
            </BigText>
          </div>
        )}
        {status !== 'pending' && (
          <>
            <div className="flex grow flex-col justify-center space-y-10 p-6 text-center">
              {/*<BigText className="">*/}
              {/*  <div className="space-y-6">*/}
              {/*    <div>Lei è il numero</div>*/}
              {/*    <div className="inline-flex aspect-square items-center justify-center rounded-full bg-white	p-8 align-middle text-2xl md:text-4xl lg:text-6xl">*/}
              {/*      {addToQueueMutation.data?.data?.records?.[0]?.ncon}*/}
              {/*    </div>*/}
              {/*  </div>*/}
              {/*</BigText>*/}

              {status === 'success' && (
                <>
                  <CheckIcon className="mx-auto size-36 text-green-600" />
                  <BigText
                    size="md"
                    className="mb-10 uppercase !leading-relaxed"
                    data-testId="status-message"
                  >
                    Ritirare il numero, grazie
                  </BigText>
                  <BigText size="sm">
                    {formattedMessaggioPren?.map((line) => (
                      <div key={line}>{line}</div>
                    ))}
                  </BigText>
                </>
              )}

              {status === 'queueError.alreadyInQueue' && (
                <BigText
                  size="md"
                  className="uppercase !leading-loose"
                  data-testId="status-message"
                >
                  <XIcon className="mx-auto mb-4 size-36 text-red-600" />
                  Attenzione, è gia presente una prenotazione per il paziente{' '}
                  <br />
                  Recarsi direttamente in sala d&apos;attesa
                </BigText>
              )}

              {status === 'queueError.generic' &&
                addToQueueMutation.data?.success && (
                  <BigText
                    size="md"
                    className="uppercase !leading-loose"
                    data-testId="status-message"
                  >
                    <XIcon className="mx-auto mb-4 size-36 text-red-600" />

                    {addToQueueMutation?.data.data.esito}
                  </BigText>
                )}

              {status === 'error' && (
                <BigText
                  size="md"
                  className="uppercase !leading-loose"
                  data-testId="status-message"
                >
                  <XIcon className="mx-auto mb-4 size-36 text-red-600" />
                  Non è stato possibile completare l&apos;operazione. <br />
                  Riprovare
                </BigText>
              )}
            </div>
            <Button className="mx-auto mb-10 h-auto w-1/2 shrink bg-gradient-to-r from-orange-500 to-red-500 px-4 py-6 text-xl hover:bg-indigo-500 hover:from-red-700 hover:to-orange-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 sm:text-2xl md:text-3xl lg:text-4xl xl:text-5xl 2xl:text-6xl" onClick={resetSession}>
              <ChevronLeft className="mr-1" size={36} /> Ricomincia
            </Button>
          </>
        )}
      </DefaultLayout.Body>
      <DefaultLayout.Footer timeoutCounter={timeoutCounter} />
    </>
  )
}
