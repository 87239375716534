import { useMemo } from 'react'
import { createFileRoute, getRouteApi, Link } from '@tanstack/react-router'
import { AnimatePresence, motion } from 'framer-motion'
import { range } from 'lodash-es'
import { z } from 'zod'

import { dayjs } from '@/lib/dayjs.ts'
import { BigText } from '@/components/BigText.tsx'
import { DefaultLayout } from '@/components/layouts/DefaultLayout.tsx'
import { SelectionButton } from '@/components/SelectionButton.tsx'
import { useSessionTimeout } from '@/routes/totem-eliminacode/$ambulatorioId/-hooks.ts'

const route = getRouteApi(
  '/totem-eliminacode/$ambulatorioId/4-insert-date-of-birth'
)

export const Route = createFileRoute(
  '/totem-eliminacode/$ambulatorioId/4-insert-date-of-birth'
)({
  component: InsertDateOfBirth,
  validateSearch: z.object({
    codaId: z.string(),
    paramCoda: z.string().optional(),
    yearRange: z.array(z.coerce.number()).optional(),
    year: z.coerce.number().optional(),
    month: z.coerce.number().optional(),
    day: z.coerce.number().optional(),
  }),
})

function splitYearsIntoRanges(
  startYear: number,
  endYear: number,
  numberOfRanges: number
): number[][] {
  const totalYears = endYear - startYear + 1
  const ranges: number[][] = []

  let currentStart = startYear
  let remainingYears = totalYears

  for (let i = 0; i < numberOfRanges; i++) {
    // Calculate the ideal range size based on remaining years and remaining ranges
    const rangeSize = Math.ceil(remainingYears / (numberOfRanges - i))
    let currentEnd = currentStart + rangeSize - 1

    // Adjust the end to ensure it ends with a year ending in 0, if possible
    if (currentEnd % 10 !== 9 && currentEnd < endYear) {
      currentEnd = Math.min(Math.floor(currentEnd / 10) * 10 + 9, endYear)
    }

    ranges.push([currentStart, currentEnd])
    remainingYears -= currentEnd - currentStart + 1
    currentStart = currentEnd + 1

    // If the remaining years are less than the number of remaining ranges,
    // adjust the ranges accordingly
    if (remainingYears < numberOfRanges - i - 1) {
      currentEnd = endYear
      ranges.push([currentStart, currentEnd])
      break
    }
  }

  return ranges
}

export function InsertDateOfBirth() {
  const params = route.useParams()
  const searchParams = route.useSearch()
  const yearRanges = useMemo(
    () => splitYearsIntoRanges(1900, new Date().getFullYear(), 5),
    []
  )
  const { timeoutCounter } = useSessionTimeout({
    seconds: 30,
    postponeOnActivity: true,
  })

  // dates handling
  const { yearRange, year, month, day } = searchParams

  const selectionStage = useMemo(() => {
    if (day) return 'confirmation'
    if (month) return 'daySelection'
    if (year) return 'monthSelection'
    if (yearRange) return 'yearSelection'

    return 'yearRangeSelection'
  }, [day, month, year, yearRange])

  const title = useMemo(() => {
    if (selectionStage === 'daySelection') {
      return 'Seleziona il giorno di nascita'
    }
    if (selectionStage === 'monthSelection') {
      return 'Seleziona il mese di nascita'
    }
    if (selectionStage === 'yearSelection') {
      return "Seleziona l'anno di nascita"
    }

    if (selectionStage === 'yearRangeSelection') {
      return "Seleziona l'intervallo dell'anno di nascita del paziente"
    }
  }, [selectionStage])

  const selectableYears = useMemo(
    () => (yearRange ? range(yearRange[0], yearRange[1] + 1) : []),
    [yearRange]
  )
  const selectableMonths = useMemo(() => (year ? range(1, 12 + 1) : []), [year])
  const selectableDays = useMemo(
    () =>
      month
        ? range(
            1,
            dayjs()
              .year(year!)
              .month(month! - 1)
              .daysInMonth() + 1
          )
        : [],
    [month, year]
  )

  return (
    <>
      <DefaultLayout.Body className="items-center justify-center">
        {/*{JSON.stringify(searchParams)}*/}
        <div className="max-w-screen-3xl flex size-full grow flex-col justify-center gap-x-10 p-4">
          {title && (
            <BigText
              size="lg"
              className="mb-10 text-center sm:text-2xl md:text-3xl lg:text-4xl xl:text-5xl 2xl:text-6xl"
            >
              {title}
            </BigText>
          )}

          <div className="flex items-center">
            <AnimatePresence mode="popLayout">
              {selectionStage === 'yearRangeSelection' && (
                <motion.div
                  key="yearRangeSelection"
                  className="mx-auto space-y-6"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                >
                  {yearRanges.map((yearRange) => (
                    <SelectionButton
                      key={yearRange.join('')}
                      className="block from-[#3d698c] text-center"
                      variant="muted"
                      asChild
                    >
                      <Link
                        params={params}
                        search={{ ...searchParams, yearRange }}
                        data-testId="yearRange-selection-btn"
                      >
                        {yearRange[0]} - {yearRange[1]}
                      </Link>
                    </SelectionButton>
                  ))}
                </motion.div>
              )}
              {selectionStage === 'yearSelection' && (
                <motion.div
                  key="yearSelection"
                  className="grid w-full grid-cols-3 gap-6 xl:grid-cols-6"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                >
                  {selectableYears?.map((year) => (
                    <SelectionButton
                      key={year}
                      className="block from-[#3d698c] text-center"
                      variant="muted"
                      asChild
                      data-testId="year-selection-btn"
                    >
                      <Link params={params} search={{ ...searchParams, year }}>
                        {year}
                      </Link>
                    </SelectionButton>
                  ))}
                </motion.div>
              )}
              {selectionStage === 'monthSelection' && (
                <motion.div
                  key="monthSelection"
                  className="grid w-full grow grid-cols-3 gap-6"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                >
                  {selectableMonths?.map((month) => (
                    <SelectionButton
                      key={month}
                      className="block from-[#3d698c] text-center uppercase"
                      variant="muted"
                      asChild
                      data-testId="month-selection-btn"
                    >
                      <Link params={params} search={{ ...searchParams, month }}>
                        {dayjs()
                          .month(month - 1)
                          .format('MMMM')}
                      </Link>
                    </SelectionButton>
                  ))}
                </motion.div>
              )}
              {selectionStage === 'daySelection' && (
                <motion.div
                  key="daySelection"
                  className="grid w-full grid-cols-5 gap-6 xl:grid-cols-7"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                >
                  {selectableDays?.map((day) => (
                    <SelectionButton
                      key={day}
                      className="block from-[#3d698c] text-center uppercase"
                      variant="muted"
                      asChild
                      data-testId="day-selection-btn"
                    >
                      <Link params={params} search={{ ...searchParams, day }}>
                        {String(day).padStart(2, '0')}
                      </Link>
                    </SelectionButton>
                  ))}
                </motion.div>
              )}
              {selectionStage === 'confirmation' && (
                <motion.div
                  key="confirmation"
                  className="flex w-full flex-col text-center"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                >
                  <BigText size="lg" className="mb-10">
                    È questa la data di nascita del paziente?
                  </BigText>

                  <div>
                    <BigText
                      size="lg"
                      className="border-secondary-foreground inline-block border-2 px-4 py-2"
                    >
                      {String(day).padStart(2, '0')}/
                      {String(month).padStart(2, '0')}/{year}
                    </BigText>
                  </div>
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        </div>
      </DefaultLayout.Body>

      <DefaultLayout.Footer
        backBtnVisible={true}
        // backBtnLabel={backBtnLabel}
        timeoutCounter={timeoutCounter}
        className="flex justify-between"
      >
        {selectionStage === 'confirmation' && (
          <div>
            <Link
              to="/totem-eliminacode/$ambulatorioId/5-printing-ticket"
              params={params}
              search={{
                ...searchParams,
                paramValue: dayjs(`${year}-${month}-${day}`).format('DDMMYYYY'),
              }}
            >
              <SelectionButton
                className="block min-w-96 text-center"
                size="md"
                data-testId="confirmation-btn"
              >
                Confermo
              </SelectionButton>
            </Link>
          </div>
        )}
      </DefaultLayout.Footer>
    </>
  )
}
