import { queryOptions, useQuery } from '@tanstack/react-query'

import { apiClient } from '@/apiClient.ts'

export const useTotemPosQueryOptions = (ospedaleId?: string) =>
  queryOptions({
    queryKey: ['ticket', ospedaleId],
    queryFn: () =>
      apiClient.api['totem-pos'][':ospedaleId']
        .$get({
          param: { ospedaleId: ospedaleId! },
        })
        .then((r) => r.json()),
    staleTime: 60 * 10 * 1000,
  })

export const useTotemPos = (
  ospedaleId?: string,
  options?: Partial<ReturnType<typeof useTotemPosQueryOptions>>
) =>
  useQuery({
    ...useTotemPosQueryOptions(ospedaleId),
    ...options,
  })
