import { useEffect } from 'react'
import { createFileRoute, Outlet } from '@tanstack/react-router'

import { DefaultLayout } from '@/components/layouts/DefaultLayout.tsx'
import { useKioskStatus } from '@/features/kiosk/useKioskStatus.ts'
import { useTotemEliminacode } from '@/features/totem-eliminacode/use-totem-eliminacode.ts'

// import { OfflineWarning } from '@/components/OfflineWarning.tsx'

export const Route = createFileRoute('/totem-eliminacode/$ambulatorioId')({
  component: EliminacodeIndex,
})

function EliminacodeIndex() {
  useKioskStatus({ performRedirect: true })
  const { ambulatorioId } = Route.useParams()
  useTotemEliminacode(ambulatorioId, {
    gcTime: 0,
    staleTime: 0,
  })

  useEffect(() => {
    if (import.meta.env.DEV) return

    document.oncontextmenu = () => false

    return () => {
      document.oncontextmenu = () => true
    }
  }, [])

  return (
    <>
      <DefaultLayout>
        <Outlet />

        {/*<OfflineWarning />*/}
      </DefaultLayout>
    </>
  )
}
