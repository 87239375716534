import {
  createFileRoute,
  getRouteApi,
  useNavigate,
} from '@tanstack/react-router'

import { BigText } from '@/components/BigText.tsx'
import { DefaultLayout } from '@/components/layouts/DefaultLayout.tsx'
import { SelectionButton } from '@/components/SelectionButton.tsx'
import { useTotemEliminacode } from '@/features/totem-eliminacode/use-totem-eliminacode.ts'
import { useSessionTimeout } from '@/routes/totem-eliminacode/$ambulatorioId/-hooks.ts'

const route = getRouteApi('/totem-eliminacode/$ambulatorioId/2-selection')

export const Route = createFileRoute(
  '/totem-eliminacode/$ambulatorioId/2-selection'
)({
  component: SelectionStep,
})

export function SelectionStep() {
  const { ambulatorioId } = route.useParams()
  // const search = route.useSearch()
  const eliminacodeQuery = useTotemEliminacode(ambulatorioId, {
    gcTime: 0,
    staleTime: 0,
  })

  const navigate = useNavigate()

  const handleQueueClick = (codaId: string) => {
    if (!eliminacodeQuery.data) return

    navigate({
      to: '/totem-eliminacode/$ambulatorioId/3-card-scan',
      params: { ambulatorioId },
      search: { codaId },
    })
  }

  const { timeoutCounter } = useSessionTimeout({
    seconds: 30,
    postponeOnActivity: true,
    enabled: !eliminacodeQuery.isLoading,
  })

  return (
    <>
      <DefaultLayout.Header>
        <BigText>Selezionare il servizio</BigText>
      </DefaultLayout.Header>

      <DefaultLayout.Body isLoading={eliminacodeQuery.isLoading}>
        <div className="grid h-full grid-cols-1 gap-10 xl:grid-cols-2">
          {eliminacodeQuery.data?.records?.map((record) => (
            <div key={record.D_CODA}>
              <SelectionButton
                className="size-full max-h-60 uppercase"
                onClick={() => handleQueueClick(record.D_CODA)}
                data-testId="service-selection-btn"
              >
                {record.D_CODA}
              </SelectionButton>
            </div>
          ))}
        </div>
      </DefaultLayout.Body>

      <DefaultLayout.Footer
        backBtnVisible={true}
        timeoutCounter={timeoutCounter}
      />
    </>
  )
}
