import React from 'react'
import {
  Document,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from '@react-pdf/renderer'
import { sumBy } from 'lodash-es'

import { Money } from '@/components/money.tsx'
import { Prestazione } from '@/routes/totem-pos/-types.ts'

export interface ReceiptProps {
  logoUrl?: string
  companyName?: string
  companyAddress?: string
  vatNumber?: string
  phone?: string
  invoiceNumber?: string
  date?: string
  items: Array<Prestazione>
}

export const Receipt: React.FC<ReceiptProps> = (receipt) => (
  <Document>
    <Page size={[220]} style={styles.page}>
      {/* Logo and Header */}
      {receipt.logoUrl && <Image style={styles.logo} src={receipt.logoUrl} />}
      <Text style={styles.header}>{receipt.companyName}</Text>
      <Text style={styles.header}>{receipt.companyAddress}</Text>
      <Text style={styles.header}>VAT Reg No: {receipt.vatNumber}</Text>
      <Text style={styles.header}>Tel: {receipt.phone}</Text>

      {/* Invoice Information */}
      <View style={styles.section}>
        <Text>Invoice No: {receipt.invoiceNumber}</Text>
        <Text>Date: {receipt.date}</Text>
      </View>

      {/* Items */}
      <View style={styles.section}>
        <View style={styles.itemsHeader}>
          <Text style={styles.bold}>Items</Text>
        </View>
        {receipt.items.map((item, index) => (
          <View key={index} style={styles.row}>
            <Text>{item.prestazione}</Text>
            <Text>
              <Money value={item.importo} />
            </Text>
          </View>
        ))}
      </View>

      {/* Total */}
      <View style={styles.totalSection}>
        <View style={styles.row}>
          <Text>Totale</Text>
          <Text>{sumBy(receipt.items, 'importo')} €</Text>
        </View>
      </View>

      {/* Footer */}
      <Text style={styles.smallText}>Arrivederci!</Text>
    </Page>
  </Document>
)

// Create styles
const styles = StyleSheet.create({
  page: {
    padding: 10,
  },
  header: {
    fontSize: 14,
    textAlign: 'center',
    marginBottom: 10,
  },
  logo: {
    width: 60,
    height: 60,
    marginBottom: 10,
    alignSelf: 'center',
  },
  section: {
    marginBottom: 10,
    paddingBottom: 10,
    borderBottom: '1px solid #000',
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 5,
  },
  bold: {
    fontFamily: 'Helvetica-Bold',
  },
  itemsHeader: {
    borderBottom: '1px solid #000',
    paddingBottom: 5,
  },
  vatSection: {
    marginTop: 10,
  },
  totalSection: {
    marginTop: 10,
    fontSize: 12,
    fontFamily: 'Helvetica-Bold',
  },
  smallText: {
    fontSize: 10,
    textAlign: 'center',
    marginTop: 20,
  },
})
