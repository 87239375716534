import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { InferRequestType, InferResponseType } from 'hono/client'

import { bus } from '@/lib/bus.ts'
import { apiClient } from '@/apiClient.ts'

type UseCheckinExemptArgs = Omit<
  InferRequestType<
    (typeof apiClient.api)['totem-pos']['check-in-exempt']['$post']
  >['json'],
  'ambulatorioId'
>
type UseCheckinExemptResponse = InferResponseType<
  (typeof apiClient.api)['totem-pos']['check-in-exempt']['$post']
>

export const useCheckinExempt = (
  options?: UseMutationOptions<
    UseCheckinExemptResponse,
    Error,
    UseCheckinExemptArgs
  >
) => {
  return useMutation<UseCheckinExemptResponse, Error, UseCheckinExemptArgs>({
    mutationKey: ['check-in-exempt'],
    mutationFn: async (variables) => {
      const { ambulatorioId } = await bus.getConfig()

      return apiClient.api['totem-pos']['check-in-exempt']
        .$post({ json: { ...variables, ambulatorioId: ambulatorioId! } })
        .then((r) => r.json())
    },
    ...options,
  })
}
