import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { InferRequestType, InferResponseType } from 'hono/client'

import { bus } from '@/lib/bus.ts'
import { apiClient } from '@/apiClient.ts'

type UseStartPaymentArgs = Omit<
  InferRequestType<
    (typeof apiClient.api)['totem-pos']['payment']['$post']
  >['json'],
  'rupp'
>
type UseStartPaymentResponse = InferResponseType<
  (typeof apiClient.api)['totem-pos']['payment']['$post']
>

export const useStartPayment = (
  options?: UseMutationOptions<
    UseStartPaymentResponse,
    Error,
    UseStartPaymentArgs
  >
) => {
  return useMutation<UseStartPaymentResponse, Error, UseStartPaymentArgs>({
    mutationKey: ['start-payment'],
    mutationFn: async (variables) => {
      const { rupp } = await bus.getConfig()

      return apiClient.api['totem-pos'].payment
        .$post({ json: { ...variables, rupp: rupp! } })
        .then((r) => r.json())
    },
    ...options,
  })
}
