import { queryOptions, useQuery } from '@tanstack/react-query'
import { InferRequestType } from 'hono/client'

import { apiClient } from '@/apiClient.ts'

type RequestType = InferRequestType<
  (typeof apiClient.api)['totem-pos'][':ospedaleId']['prestazioni']['$get']
>

export type UseFindPrestazioniArgs = RequestType['param'] & RequestType['query']

export const findPrestazioniQueryOptions = (args: UseFindPrestazioniArgs) =>
  queryOptions({
    queryKey: ['prestazioni', args],
    queryFn: () =>
      apiClient.api['totem-pos'][':ospedaleId'].prestazioni
        .$get({
          param: { ospedaleId: args.ospedaleId },
          query: {
            searchMethod: args.searchMethod,
            searchValue: args.searchValue,
          },
        })
        .then((r) => r.json()),
    staleTime: 0,
  })

export const useFindPrestazioni = (
  args: UseFindPrestazioniArgs,
  options?: Partial<ReturnType<typeof findPrestazioniQueryOptions>>
) => {
  return useQuery({
    ...findPrestazioniQueryOptions(args),
    ...options,
  })
}
