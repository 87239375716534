import { useEffect } from 'react'
import { createFileRoute } from '@tanstack/react-router'
import { z } from 'zod'

// import { Button } from '@/components/ui/button.tsx'
import { BigText } from '@/components/BigText.tsx'
import { DefaultLayout } from '@/components/layouts/DefaultLayout.tsx'
import { useGetPrestazione } from '@/features/totem-pos/use-get-prestazione.ts'
import { useSendInvoice } from '@/features/totem-pos/use-send-invoice.ts'
import { useSessionTimeout } from '@/routes/totem-eliminacode/$ambulatorioId/-hooks.ts'
import { checkinParametersSchema } from '@/routes/totem-pos/$ospedaleId/-checkin-parameters-schema.ts'

export const Route = createFileRoute(
  '/totem-pos/$ospedaleId/5-send-invoice/send'
)({
  component: InvoiceSend,
  validateSearch: z.intersection(
    checkinParametersSchema,
    z.object({
      invoiceMode: z.enum(['sms', 'email']),
      input: z.string(),
      prestazioneId: z.string(),
    })
  ),
})

function InvoiceSend() {
  const { ospedaleId } = Route.useParams()
  const { prestazioneId, invoiceMode, input, checkinMethod, checkinValue } =
    Route.useSearch()
  const sendInvoiceMutation = useSendInvoice()
  const navigate = Route.useNavigate()
  const { timeoutCounter } = useSessionTimeout({
    seconds: 20,
    enabled: sendInvoiceMutation.isSuccess,
  })

  const { data: prestazione } = useGetPrestazione({
    ospedaleId,
    prestazioneId: prestazioneId!,
    searchMethod: checkinMethod,
    searchValue: checkinValue,
  })

  useEffect(() => {
    if (prestazione && invoiceMode && input) {
      sendInvoiceMutation.mutate({
        numRicetta: prestazione.numero_ricetta,
        invoiceMode,
        input,
      })
    }
  }, [prestazione, invoiceMode, input])

  useEffect(() => {
    if (sendInvoiceMutation.isSuccess) {
      navigate({
        to: '/totem-pos/$ospedaleId/6-success',
        search: {
          message: `Ricevuta inviata.\nArrivederci`,
        },
      })
    }
  }, [sendInvoiceMutation.isSuccess])

  return (
    <>
      <DefaultLayout.Body>
        <div className="flex grow flex-col items-center justify-center">
          {sendInvoiceMutation.isPending && (
            <BigText>Invio della ricevuta in corso...</BigText>
          )}
        </div>
      </DefaultLayout.Body>

      <DefaultLayout.Footer timeoutCounter={timeoutCounter} />
    </>
  )
}
