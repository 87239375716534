import { useState } from 'react'
import {
  createFileRoute,
  getRouteApi,
  Link,
  useNavigate,
} from '@tanstack/react-router'
import { XIcon } from 'lucide-react'
import { z } from 'zod'

import { useCardScan } from '@/hooks/useCardScan.ts'
import { BigText } from '@/components/BigText.tsx'
import { DefaultLayout } from '@/components/layouts/DefaultLayout.tsx'
import { SelectionButton } from '@/components/SelectionButton.tsx'
import useCardVideo from '@/assets/videos/use-card-2.mp4'
import { useSessionTimeout } from '@/routes/totem-eliminacode/$ambulatorioId/-hooks.ts'

const fiscalCodeRegex = new RegExp(
  "(?<fiscalCode>[A-Za-z]{6}[0-9LMNPQRSTUV]{2}[A-Za-z]{1}[0-9LMNPQRSTUV]{2}[A-Za-z]{1}[0-9LMNPQRSTUV]{3}[A-Za-z]{1})(\\s?(?<lastname>[A-Za-z']+\\s{1,2}[A-Za-z']+|[A-Za-z']+)\\s{1,2}(?<firstname>([A-Za-z']+\\s[A-Za-z']+)|[A-Za-z']+)?)?"
)

const route = getRouteApi('/totem-eliminacode/$ambulatorioId/3-card-scan')

export const Route = createFileRoute(
  '/totem-eliminacode/$ambulatorioId/3-card-scan'
)({
  component: CardScanStep,
  validateSearch: z.object({
    codaId: z.string(),
    paramCoda: z.string().optional(),
  }),
})

export function CardScanStep() {
  const navigate = useNavigate()
  const params = route.useParams()
  const searchParams = route.useSearch()
  const startOver = () => {
    navigate({ to: '/totem-eliminacode/$ambulatorioId', params })
  }

  const [status, setStatus] = useState<'waiting' | 'scanError'>('waiting')

  const { timeoutCounter, startTimeout } = useSessionTimeout({
    seconds: 30,
    postponeOnActivity: true,
  })

  useCardScan({
    onScan(input, matchedPattern) {
      if (matchedPattern) {
        const fiscalCode = matchedPattern.exec(input)?.groups?.fiscalCode

        navigate({
          to: '/totem-eliminacode/$ambulatorioId/5-printing-ticket',
          params,
          search: {
            ...searchParams,
            paramValue: fiscalCode,
          },
        })
      } else {
        setStatus('scanError')
      }

      startTimeout()
    },
    patterns: [fiscalCodeRegex],
  })

  return (
    <>
      <DefaultLayout.Body className="items-center justify-center">
        {status === 'waiting' && (
          <div className="grid h-full max-w-screen-2xl grow grid-cols-1 justify-center gap-x-10 bg-white/50 p-4 xl:grid-cols-2">
            <div className="flex h-full items-center text-center">
              <BigText size="lg">
                Utilizzare{' '}
                <span className="text-nowrap">la carta d&apos;identità</span> o{' '}
                <span className="text-nowrap">la tessera sanitaria</span> del
                paziente
              </BigText>
            </div>
            <div className="h-[50rem] w-full">
              <video
                className="mx-auto h-full object-contain"
                src={useCardVideo}
                autoPlay
                loop
                muted
              />
            </div>
          </div>
        )}

        {status === 'scanError' && (
          <>
            <XIcon className="size-36 text-red-600" />
            <BigText className="flex text-center">
              Lettura carta non valida <br />
              Riprovare
            </BigText>
          </>
        )}
      </DefaultLayout.Body>

      <DefaultLayout.Footer
        backBtnVisible={true}
        backBtnLabel="Ricomincia"
        onBackBtnClick={startOver}
        timeoutCounter={timeoutCounter}
        className="grid grid-cols-3 justify-around"
      >
        <Link
          className="mx-auto inline-block text-center"
          to="/totem-eliminacode/$ambulatorioId/4-insert-date-of-birth"
          params={params}
          search={searchParams}
        >
          <SelectionButton
            size="md"
            variant="muted"
            data-testId="no-documents-btn"
          >
            Non ho documenti
          </SelectionButton>
        </Link>
      </DefaultLayout.Footer>
    </>
  )
}
