import { useMemo } from 'react'
import { zodResolver } from '@hookform/resolvers/zod'
import { createFileRoute } from '@tanstack/react-router'
import { compact } from 'lodash-es'
import { useForm } from 'react-hook-form'
import { z } from 'zod'

import { phoneNumberRegex } from '@/lib/phone-number-regex.ts'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
} from '@/components/ui/form.tsx'
import { BigText } from '@/components/BigText.tsx'
import { KeyboardInput } from '@/components/keyboard-input.tsx'
import { DefaultLayout } from '@/components/layouts/DefaultLayout.tsx'
import { SelectionButton } from '@/components/SelectionButton.tsx'
import { useFindPrestazioni } from '@/features/totem-pos/use-find-prestazioni.ts'
import { checkinParametersSchema } from '@/routes/totem-pos/$ospedaleId/-checkin-parameters-schema.ts'

export const Route = createFileRoute(
  '/totem-pos/$ospedaleId/5-send-invoice/$invoiceMode'
)({
  component: () => <InvoicePrint />,
  validateSearch: z.intersection(
    checkinParametersSchema,
    z.object({
      insertCustom: z.boolean().optional(),
      prestazioneId: z.string(),
    })
  ),
})

function InvoicePrint() {
  const { ospedaleId } = Route.useParams()
  const { invoiceMode } = Route.useParams()
  const searchParams = Route.useSearch()
  const { insertCustom, checkinMethod, checkinValue, prestazioneId } =
    searchParams
  const navigate = Route.useNavigate()

  const findPrestazioniQuery = useFindPrestazioni({
    ospedaleId,
    searchMethod: checkinMethod,
    searchValue: checkinValue,
  })
  const prestazione = useMemo(() => {
    if (!findPrestazioniQuery.data?.success) return null

    return findPrestazioniQuery.data.data?.records?.find(
      (r) => r.id_interno === prestazioneId
    )
  }, [findPrestazioniQuery.data, prestazioneId])

  const contactOptions = useMemo(
    () =>
      compact([
        invoiceMode === 'sms' ? prestazione?.telefono : prestazione?.email,
      ]),
    [prestazione, invoiceMode]
  )

  const formSchema = useMemo(() => {
    if (invoiceMode === 'sms') {
      return z.object({
        input: z.string().min(2).regex(phoneNumberRegex),
      })
    }

    return z.object({
      input: z.string().email(),
    })
  }, [invoiceMode])

  // form
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: { input: '' },
  })

  const handleSelectOption = (input: string) =>
    navigate({
      to: '/totem-pos/$ospedaleId/5-send-invoice/send',
      search: {
        ...searchParams,
        prestazioneId: prestazione!.id_interno,
        invoiceMode: invoiceMode as 'email' | 'sms',
        input,
      },
    })

  const onSubmit = () => {
    handleSelectOption(form.getValues().input)
  }

  return (
    <>
      <DefaultLayout.Header>
        {insertCustom ? (
          <>
            {invoiceMode === 'sms' && (
              <BigText>
                Inserisci il numero di telefono a cui vuoi inviare la ricevuta
              </BigText>
            )}
            {invoiceMode === 'email' && (
              <BigText>
                Inserisci l&apos;email a cui vuoi inviare la ricevuta
              </BigText>
            )}
          </>
        ) : (
          <BigText>
            A quale {invoiceMode === 'sms' ? 'numero di telefono' : 'email'}{' '}
            vuoi inviare la ricevuta?
          </BigText>
        )}
      </DefaultLayout.Header>
      <DefaultLayout.Body className="items-center justify-center">
        {insertCustom && (
          <Form {...form}>
            <form
              onSubmit={form.handleSubmit(onSubmit)}
              className="flex w-full max-w-6xl items-center gap-x-10"
            >
              <div className="grow">
                <FormField
                  control={form.control}
                  name="input"
                  render={({ field }) => (
                    <FormItem>
                      <FormControl>
                        <KeyboardInput
                          {...field}
                          className="p-10 sm:text-lg md:text-xl lg:text-2xl xl:text-3xl 2xl:text-4xl"
                          autoFocus
                          autoComplete="off"
                          onChange={field.onChange}
                          keyboardType={
                            invoiceMode === 'email' ? 'email' : 'numeric'
                          }
                        />
                      </FormControl>
                      {/*<FormDescription>*/}
                      {/*  This is your public display name.*/}
                      {/*</FormDescription>*/}
                      {/*<FormMessage />*/}
                    </FormItem>
                  )}
                />
              </div>
              <div className="grow-0">
                <SelectionButton
                  className="h-[82px] min-w-40 items-center"
                  size="sm"
                  disabled={!form.formState.isValid}
                >
                  Invia
                </SelectionButton>
              </div>
            </form>
          </Form>
        )}

        {!insertCustom && (
          <div className="flex size-full flex-col justify-around gap-y-20 border-gray-300">
            {contactOptions.map((option) => (
              <SelectionButton
                key={option}
                className="max-h-60 uppercase"
                onClick={() => handleSelectOption(option)}
              >
                {option}
              </SelectionButton>
            ))}

            <SelectionButton
              className="max-h-60 uppercase"
              onClick={() =>
                navigate({ search: { ...searchParams, insertCustom: true } })
              }
            >
              {invoiceMode === 'sms' && 'Un nuovo numero'}
              {invoiceMode === 'email' && 'Un nuovo indirizzo email'}
            </SelectionButton>
          </div>
        )}
      </DefaultLayout.Body>
      <DefaultLayout.Footer backBtnVisible={true} />
    </>
  )
}
