import { z } from 'zod';
export const configSchema = z
    .object({
    mode: z
        .union([z.literal('configuration'), z.literal('kiosk')])
        .default('configuration'),
    types: z
        .array(z.union([z.literal('eliminacode'), z.literal('ticket')]))
        .optional(),
    ambulatorioId: z.string().min(1).optional(), // Initially optional
    ospedaleId: z.string().min(1).optional(), // Initially optional
    rupp: z.string().min(1).optional(), // Initially optional
    printerName: z.string().min(1),
    password: z
        .string()
        .min(8, { message: 'La password deve contenere almeno 8 caratteri' })
        .regex(/[A-Z]/, {
        message: 'La password deve contenere almeno una lettera maiuscola',
    })
        .regex(/[a-z]/, {
        message: 'La password deve contenere almeno una lettera minuscola',
    }),
    // .regex(/[0-9]/, { message: "La password deve contenere almeno un numero" })
    // .regex(/[\!\@\#\$\%\^\&\*\(\)\_\+\-\=\[\]\{\}\;\:\'\"\<\>\,\.\?\/\~\`]/, { message: "La password deve contenere almeno un carattere speciale" });
})
    .strip()
    .refine((data) => {
    if (data.types?.includes('eliminacode')) {
        return data.ambulatorioId && data.ambulatorioId.length >= 4;
    }
    return true;
}, {
    message: "codiceAmbulatorio is required to be at least 4 characters long when 'types' includes 'eliminacode'",
    path: ['codiceAmbulatorio'], // Pointing out where the error should be indicated
})
    .refine((data) => {
    if (data.types?.includes('ticket')) {
        return data.ospedaleId && data.ospedaleId.length >= 4;
    }
    return true;
}, {
    message: "ospedaleId is required to be at least 4 characters long when 'types' includes 'ticket'",
    path: ['ospedaleId'], // Pointing out where the error should be indicated
})
    .refine((data) => {
    if (data.types?.includes('ticket')) {
        return data.rupp && data.rupp.length >= 1;
    }
    return true;
}, {
    message: "rupp is required to be at least 1 characters long when 'types' includes 'ticket'",
    path: ['rupp'], // Pointing out where the error should be indicated
})
    .transform((arg) => ({ ...arg, types: undefined }));
