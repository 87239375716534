import React, { useEffect, useMemo, useRef, useState } from 'react'
import Keyboard, { KeyboardLayoutObject } from 'react-simple-keyboard'

import 'react-simple-keyboard/build/css/index.css'
import './keyboard-input.css'

import { cn } from '@/lib/utils.ts'
import { Input, InputProps } from '@/components/ui/input.tsx'
import buttonSound from '@/assets/sounds/button-sound.mp3'

const buttonSoundAudio = new Audio(buttonSound)
buttonSoundAudio.preload = 'auto'

// export interface KeyboardInputProps extends InputProps {
//   onChange?: (...event: Array<unknown>) => void
//   keyboardType?: 'full' | 'email' | 'numeric' | 'full-maiusc'
//   centered?: boolean
// }

export type KeyboardInputProps = InputProps & {
  onChange?: (...event: Array<unknown>) => void
  keyboardType?: 'full' | 'email' | 'numeric'
  keyboardLayout?: KeyboardLayoutObject
  centered?: boolean
}

export const KeyboardInput: React.FC<KeyboardInputProps> = (props) => {
  const [inputValue, setInputValue] = useState(props.value)
  const [selectedLayout, setSelectedLayout] = useState('default')
  const [keyboardVisible, setKeyboardVisible] = useState(false)
  const [keyboardPosition, setKeyboardPosition] = useState({ top: 0, left: 0 })
  const inputRef = useRef<HTMLInputElement | null>(null)
  const keyboardRef = useRef<HTMLInputElement | null>(null)
  const keyboardInstanceRef = useRef<unknown>(null)

  const handleKeyboardChange = (input: string) => {
    setInputValue(input)
    if (inputRef.current) {
      inputRef.current.value = input // Update the input field with keyboard input
      props.onChange?.(input)
    }
  }

  const handleShift = () => {
    const newLayout = selectedLayout === 'default' ? 'shift' : 'default'
    setSelectedLayout(newLayout)
  }

  const onKeyPress = (button: string) => {
    if (button === '{shift}' || button === '{lock}') handleShift()

    buttonSoundAudio.play()
  }

  const handleFocus = () => {
    if (inputRef.current) {
      const rect = inputRef.current.getBoundingClientRect()
      const top = rect.bottom + window.scrollY + 10 // Position the keyboard below the input
      const left = window.innerWidth / 2 // Center the keyboard horizontally
      setKeyboardPosition({ top, left })
      setKeyboardVisible(true)
    }
  }

  const handleBlur = () => {
    setTimeout(() => setKeyboardVisible(false), 200) // Delay hiding the keyboard to allow interactions
  }

  // Autofocus check
  useEffect(() => {
    // Define a function to handle activeElement check and logic
    const timeout = setTimeout(() => {
      if (document.activeElement === inputRef.current) {
        setKeyboardVisible(true)

        // Get input's position and calculate the keyboard position
        const rect = inputRef.current!.getBoundingClientRect()
        const top = rect.bottom + window.scrollY + 10 // Add 10px for padding below the input
        const left = window.innerWidth / 2 // Center the keyboard horizontally

        setKeyboardPosition({ top, left })
      }
    }, 200)

    return () => {
      clearTimeout(timeout)
    }
  }, [])

  useEffect(() => {
    function handleFocus() {
      setKeyboardVisible(true)

      // Get input's position and calculate the keyboard position
      const rect = inputRef.current!.getBoundingClientRect()
      const top = rect.bottom + window.scrollY + 10 // Add 10px for padding below the input
      const left = window.innerWidth / 2 // Center the keyboard horizontally

      setKeyboardPosition({ top, left })
    }

    inputRef.current?.addEventListener('focus', handleFocus, true)
    // inputRef.current?.addEventListener('blur', handleBlur)

    return () => {
      inputRef.current?.removeEventListener('focus', handleFocus, true)
      // inputRef.current?.removeEventListener('blur', handleBlur)
    }
  }, [setKeyboardVisible, setKeyboardPosition])

  useEffect(() => {
    function handleClick(e: MouseEvent) {
      const clickedInput = inputRef.current === e.target
      const clickedInsideKeyboard = keyboardRef.current?.contains(
        e.target as Node
      )
      if (keyboardVisible && !clickedInput && !clickedInsideKeyboard) {
        setKeyboardVisible(false)
      }
      if (keyboardVisible && clickedInsideKeyboard) {
        inputRef.current?.focus()
      }
    }

    document.addEventListener('click', handleClick)

    return () => {
      document.removeEventListener('click', handleClick)
    }
  }, [keyboardInstanceRef, keyboardVisible])

  useEffect(() => {
    // @ts-expect-error eh si
    keyboardInstanceRef.current?.setInput(props.value)
  }, [props.value])

  const keyboardType = 'keyboardType' in props ? props.keyboardType : null

  const keyboardLayout = useMemo<KeyboardLayoutObject>(() => {
    if (keyboardType === 'full') {
      return {
        default: [
          '1 2 3 4 5 6 7 8 9 0 {bksp}',
          '{d} q w e r t y u i o p \\',
          '{d} {d} a s d f g h j k l {lock}',
          '{d} {d} {d} z x c v b n m , .',
          '{space}',
        ],
        shift: [
          '1 2 3 4 5 6 7 8 9 0 {bksp}',
          '{d} Q W E R T Y U I O P \\',
          '{d} {d} A S D F G H J K L {lock}',
          '{d} {d} {d} Z X C V B N M , .',
          '{space}',
        ],
      }
    }

    if (keyboardType === 'email') {
      return {
        default: [
          '1 2 3 4 5 6 7 8 9 0 {bksp}',
          '{d} q w e r t y u i o p',
          '{d} {d} a s d f g h j k l',
          '{d} {d} {d} z x c v b n m . @',
          '{@gmail.com} {@hotmail.com} {@hotmail.it}',
        ],
        shift: [
          '1 2 3 4 5 6 7 8 9 0 {bksp}',
          '{d} Q W E R T Y U I O P',
          '{d} {d} A S D F G H J K L',
          '{d} {d} {d} Z X C V B N M .',
          '{@gmail.com} {@hotmail.com} {@hotmail.it}',
        ],
      }
    }

    return {
      default: [
        '1 2 3 {bksp}', //
        '4 5 6', //
        '7 8 9', //
        '{d} {d} 0 +',
      ],
      shift: [
        '1 2 3 {bksp}', //
        '4 5 6', //
        '7 8 9', //
        '{d} {d} 0 +',
      ],
    }
  }, [keyboardType])

  return (
    <>
      <Input
        ref={inputRef}
        value={inputValue}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onChange={(e) => setInputValue(e.target.value)}
        {...props}
      />

      <div
        ref={keyboardRef}
        className={cn(
          `fixed z-[1000] -translate-x-1/2 shadow-2xl`,
          keyboardType === 'numeric' && 'max-w-3xl',
          !keyboardVisible && 'hidden'
        )}
        style={{
          top: `${keyboardPosition.top}px`,
          left: `${keyboardPosition.left}px`,
        }}
      >
        <Keyboard
          keyboardRef={(r) => (keyboardInstanceRef.current = r)}
          layoutName={selectedLayout}
          onChange={handleKeyboardChange}
          onKeyPress={onKeyPress}
          layout={props.keyboardLayout ?? keyboardLayout}
          display={{
            '{bksp}': '⌫',
            '{lock}': '⇧',
            '{enter}': 'Invio',
            '{space}': '&nbsp;',
            '{@gmail.com}': '@gmail.com',
            '{@hotmail.com}': '@hotmail.com',
            '{@hotmail.it}': '@hotmail.it',
            '{d}': '&nbsp;',
          }}
        />
      </div>
    </>
  )
}
